// Feature Types
import { HeaderPatternMenuProps } from 'features/appBuilder/patterns/features/header/types/menu';

export const headerPatternMenuDefaults: HeaderPatternMenuProps = {
  isActive: true,
  one: {
    containerId: '',
    containerTitle: '',
    isActive: true,
    itemCount: 6,
    padding: '0px',
    fontSize: '14',
    textDirection: 'rtl',
    textAlign: 'start',
    itemImageSize: 0,
    titleFontSize: '18',
    color: '#59666D',
    bgColor: 'rgba(0, 0, 0, 0)',
    hoverColor: '#59666D',
    activeColor: '#12252E',
    activeBgColor: 'rgba(0, 0, 0, 0)',
    titleColor: '#59666D',
    titleBgColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
    subMenuColor: '#59666D',
    subMenuHoverColor: '#59666D',
    subMenuBg: '#C1C8D3',
    subMenuBgHoverColor: '#C1C8D3',
  },
  two: {
    containerId: '',
    containerTitle: '',
    isActive: true,
    itemCount: 6,
    padding: '0px',
    fontSize: '14',
    textDirection: 'rtl',
    textAlign: 'start',
    itemImageSize: 0,
    titleFontSize: '18',
    hoverColor: '#59666D',
    color: '#59666D',
    bgColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
    activeColor: '#12252E',
    activeBgColor: 'rgba(0, 0, 0, 0)',
    titleBgColor: 'rgba(0, 0, 0, 0)',
    titleColor: '#59666D',
    subMenuColor: '#59666D',
    subMenuBg: '#C1C8D3',
    subMenuHoverColor: '#59666D',
    subMenuBgHoverColor: '#C1C8D3',
  },
};
