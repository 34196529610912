import { type FC, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';

// Feature Compoentns
import GroupPatternAddColumnButton from 'features/appBuilder/patterns/features/group/components/Columns/Add';
import GroupPatternColumn from 'features/appBuilder/patterns/features/group/components/Columns/Column';

// Feature Utilities
import { getGroupPatternColumnDefaults } from 'features/appBuilder/patterns/features/group/utilities/defaults/column';

// Custom Types
export interface GroupPatternColumnsProps {}

type ColKey = 'columnOne' | 'columnTwo' | 'columnThree';
const GroupPatternColumns: FC<GroupPatternColumnsProps> = (props) => {
  // Hooks
  const [colCount, setColCount] = useState<number>(0);
  const [itemIdToEdit, setItemIdToEdit] = useState<string>('');
  const [collapsedColumn, setCollapsedColumn] = useState<ColKey>('columnOne');
  const { control, getValues, setValue } = useFormContext();

  useEffect(() => {
    initializeTabsCount();
  }, []);

  // Utilities
  const initializeTabsCount = () => {
    let count = 0;

    const columnOne = getValues('columnOne');
    const columnTwo = getValues('columnTwo');
    const columnThree = getValues('columnThree');

    if (columnOne && columnOne.id) count++;
    if (columnTwo && columnTwo.id) count++;
    if (columnThree && columnThree.id) count++;

    if (count !== colCount) setColCount(count);
  };

  const handleAddColumn = () => {
    if (colCount < 3) {
      const key =
        colCount === 0
          ? 'columnOne'
          : colCount === 1
          ? 'columnTwo'
          : 'columnThree';

      const defaults = getGroupPatternColumnDefaults();
      setValue(key, defaults);
      setColCount(colCount + 1);
    }
  };

  const handleRemove =
    (keyName: 'columnOne' | 'columnTwo' | 'columnThree') => () => {
      if (keyName === 'columnThree') {
        setValue('columnThree', null);
      }

      if (keyName === 'columnTwo') {
        if (colCount > 2) {
          const colThree = getValues('columnThree');
          setValue('columnTwo', colThree);
          setValue('columnThree', null);
        } else {
          setValue('columnTwo', null);
        }
      }

      if (keyName === 'columnOne') {
        if (colCount === 3) {
          const colTwo = getValues('columnTwo');
          const colThree = getValues('columnThree');
          setValue('columnOne', colTwo);
          setValue('columnTwo', colThree);
          setValue('columnThree', null);
        } else if (colCount === 2) {
          const colTwo = getValues('columnTwo');
          setValue('columnOne', colTwo);
          setValue('columnTwo', null);
        } else if (colCount === 1) {
          setValue('columnOne', null);
        }
      }

      setColCount(colCount - 1);
    };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {(['columnOne', 'columnTwo', 'columnThree'] as ColKey[]).map(
        (col, index) => (
          <Controller
            control={control}
            key={col + index}
            name={col}
            render={({ field }) =>
              field.value ? (
                <GroupPatternColumn
                  collapsed={collapsedColumn === col}
                  flexGrow={collapsedColumn === col}
                  title={
                    col === 'columnOne'
                      ? 'اول'
                      : col === 'columnTwo'
                      ? 'دوم'
                      : 'سوم'
                  }
                  reference={col}
                  itemIdToEdit={itemIdToEdit}
                  onItemEditMode={setItemIdToEdit}
                  onRemove={handleRemove(col)}
                  onCollapsedChange={() => setCollapsedColumn(col)}
                />
              ) : (
                <></>
              )
            }
          />
        )
      )}
      {colCount < 3 && (
        <GroupPatternAddColumnButton
          sx={{ flexGrow: colCount > 0 ? 0 : 1 }}
          onClick={handleAddColumn}
        />
      )}
    </Box>
  );
};

export default GroupPatternColumns;
