import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import TextFieldNumber from 'core/components/shared/TextField/number';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import Box from 'core/components/base/layout/Box';
import ColorPicker from 'core/components/shared/Picker/Color';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import MenuContainerSelect from 'features/menu/menus/components/shared/dropdown';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternMenuItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  iconImageSize?: boolean;
  menuTitle?: boolean;
  noItemCount?: boolean;
  noSubMenu?: boolean;
}

const PatternMenuItem: FC<PatternMenuItemProps> = (props) => {
  // Props
  const {
    reference,
    menuTitle,
    iconImageSize,
    noSubMenu,
    noItemCount,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  // Helpers

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper label='منبع'>
          <Controller
            control={control}
            name={`${reference}.containerId`}
            render={({ field }) => (
              <MenuContainerSelect
                disabled={loading}
                defaultValue={field.value}
                onChange={(opt) => {
                  setValue(field.name, opt.id);
                  setValue(`${reference}.containerTitle`, opt.data.title);
                }}
              />
            )}
          />
        </InputWrapper>
        {!noItemCount && (
          <InputWrapper sx={{ maxWidth: '4.5rem' }} label='تعداد آیتم'>
            <Controller
              control={control}
              name={`${reference}.itemCount`}
              rules={{
                onBlur: (e) => {
                  const strValue = e.target.value as string;
                  const value = parseInt(strValue);

                  setValue(
                    `${reference}.itemCount`,
                    typeof value === 'number' ? value : 0
                  );
                },
              }}
              render={({ field }) => (
                <TextFieldNumber disabled={loading} fullWidth {...field} />
              )}
            />
          </InputWrapper>
        )}
      </Box>
      {iconImageSize && (
        <Box>
          <InputWrapper label='اندازه تصاویر منو'>
            <Controller
              control={control}
              name={`${reference}.itemImageSize`}
              render={({ field }) => (
                <TextFieldNumber disabled={loading} fullWidth {...field} />
              )}
            />
          </InputWrapper>
        </Box>
      )}
      {menuTitle && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            gap: 3,
          }}
        >
          <InputWrapper
            sx={{ width: '10rem', flexGrow: 1 }}
            label='اندازه عنوان'
          >
            <Controller
              control={control}
              name={`${reference}.titleFontSize`}
              render={({ field }) => (
                <TextFieldNumber disabled={loading} fullWidth {...field} />
              )}
            />
          </InputWrapper>
          <InputWrapper sx={{ pb: '0.5rem' }} row label='عنوان'>
            <Controller
              control={control}
              name={`${reference}.titleColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper sx={{ pb: '0.5rem' }} row label='بکگراند عنوان'>
            <Controller
              control={control}
              name={`${reference}.titleBgColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        </Box>
      )}
      {!noSubMenu && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2 }}>
            {[
              { title: 'متن منو فعال', key: 'activeColor' },
              { title: 'بکگراند منو فعال', key: 'activeBgColor' },
            ].map((item) => (
              <InputWrapper
                key={reference + item.title + item.key}
                sx={{ pb: '0.5rem' }}
                row
                label={item.title}
              >
                <Controller
                  control={control}
                  name={`${reference}.${item.key}`}
                  render={({ field }) => (
                    <ColorPicker
                      disabled={loading}
                      color={field.value}
                      onColorChange={(color) => setValue(field.name, color)}
                    />
                  )}
                />
              </InputWrapper>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2 }}>
            {[
              { title: 'متن زیرمنو', key: 'subMenuColor' },
              { title: 'بکگراند زیرمنو', key: 'subMenuBg' },
            ].map((item) => (
              <InputWrapper
                key={reference + item.title + item.key}
                sx={{ pb: '0.5rem' }}
                row
                label={item.title}
              >
                <Controller
                  control={control}
                  name={`${reference}.${item.key}`}
                  render={({ field }) => (
                    <ColorPicker
                      disabled={loading}
                      color={field.value}
                      onColorChange={(color) => setValue(field.name, color)}
                    />
                  )}
                />
              </InputWrapper>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2 }}>
            {[
              { title: 'هاور متن زیرمنو', key: 'subMenuHoverColor' },
              { title: 'هاور بکگراند زیرمنو', key: 'subMenuBgHoverColor' },
            ].map((item) => (
              <InputWrapper
                key={reference + item.title + item.key}
                sx={{ pb: '0.5rem' }}
                row
                label={item.title}
              >
                <Controller
                  control={control}
                  name={`${reference}.${item.key}`}
                  render={({ field }) => (
                    <ColorPicker
                      disabled={loading}
                      color={field.value}
                      onColorChange={(color) => setValue(field.name, color)}
                    />
                  )}
                />
              </InputWrapper>
            ))}
          </Box>
        </Box>
      )}
    </PatternItemContainer>
  );
};

export default PatternMenuItem;
