import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTextItem from 'features/appBuilder/patterns/core/components/shared/items/Text';
import PatternImageItem from 'features/appBuilder/patterns/core/components/shared/items/Image';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';

// Custom Types
export interface SearchPatternCardConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title' | 'body' | 'image' | 'display';

const SearchPatternCardConfigSection: FC<
  SearchPatternCardConfigSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    body: false,
    image: false,
    display: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات کارت'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='cardConfig'
    >
      <PatternTextItem
        title='عنوان'
        reference='cardConfig.title'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternTextItem
        title='متن'
        reference='cardConfig.body'
        collapsed={collapsed.body}
        onCollapsedChange={handleCollapseChanged('body')}
        lineClampOptions={[1, 2, 3, 4, 5]}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternImageItem
        title='تنظیمات عکس'
        reference='cardConfig.image'
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        noUpload
        playIcon
        include={['padding']}
      />
      <PatternEmptyItem
        title='نمایش کارت‌ها'
        reference='cardConfig.display'
        collapsed={collapsed.display}
        onCollapsedChange={handleCollapseChanged('display')}
        include={['padding', 'align', 'cardBg', 'cardBgHover']}
      />
    </PatternSectionContainer>
  );
};

export default SearchPatternCardConfigSection;
