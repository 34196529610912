// Types
import type { Dispatch, SetStateAction } from 'react';

// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { isSucceed } from 'core/utilities/helper';
import { getToken } from 'core/utilities/token/token';
import { bakeFile } from 'features/file/files/utilities/files/api/baker';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import {
  getAuthTokenName,
  getAppModuleBaseURL,
} from 'core/utilities/helper/getEnvVariables';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type { FileDataProps, FileProps } from 'features/file/files/types';
import type {
  FileBaseDataToSaveProps,
  FileDataToSaveKey,
} from 'features/file/files/types/fileToSave';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type {
  ApiGetDocHandler,
  ApiGetDocsHandler,
} from 'core/types/api/hook/handler';

const moduleBaseURL = getAppModuleBaseURL();

/**
 * Fetches a file by its ID.
 *
 * @param {string} id - The ID of the file to fetch.
 * @param {AbortSignal} signal - The signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<FileProps>>} The status of the request and the file data, if found.
 */
export const getFile = async (
  id: string,
  signal: AbortSignal
): Promise<ApiGetDocResponse<FileProps>> => {
  const { getFileURL } = getEndpoint();
  const { status, data: response } = await apiHandler.get<{
    docs: FileProps;
  }>(`${getFileURL}/${id}`, {
    baseURL: moduleBaseURL,
    signal,
  });
  return { status, doc: response?.docs };
};

export const getNewFile: ApiGetDocHandler<FileProps> = async (id, signal) => {
  const { getFileURL } = getEndpoint();
  const { status, data: response } = await apiHandler.get<{
    docs: FileProps;
  }>(`${getFileURL}/${id}`, {
    baseURL: moduleBaseURL,
    signal,
  });

  return { status, doc: response?.docs };
};

/**
 * Fetches a list of files based on the provided queries.
 *
 * @param {AbortSignal} signal - The signal to abort the request.
 * @param {Record<string, any>} [queries] - Optional queries to filter the files.
 * @returns {Promise<ApiGetDocsResponse<FileProps>>} The status of the request, pagination information, and the list of files.
 */
export const getFiles = async (
  signal: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<FileProps>> => {
  const { filesURL } = getEndpoint();
  const { data: response, status } = await apiHandler.get<{
    docs: { data: FileProps[]; paginate: ApiPaginationProps };
  }>(getUrlWithQueryString(filesURL, queries), {
    baseURL: moduleBaseURL,
    signal,
  });
  const page = {
    current: response?.docs.paginate.page || 1,
    size: response?.docs.paginate.limit || 20,
    totalDocs: response?.docs.paginate.totalDocs || 0,
  };
  const list = response?.docs?.data;
  return { status, list, page };
};

export const getNewFiles: ApiGetDocsHandler<FileProps> = async (configs) => {
  const { filesURL } = getEndpoint();

  const { data: response, status } = await apiHandler.get<{
    docs: { data: FileProps[]; paginate: ApiPaginationProps };
  }>(getUrlWithQueryString(filesURL, configs?.query), {
    baseURL: moduleBaseURL,
    signal: configs?.signal,
  });
  const page = {
    current: response?.docs.paginate.page || 1,
    size: response?.docs.paginate.limit || 20,
    totalDocs: response?.docs.paginate.totalDocs || 0,
  };
  const list = response?.docs?.data;

  return { status, list, page };
};

/**
 * Edit a file with the provided ID.
 * @param fileId - The ID of the file to edit.
 * @param fileData - The updated file data.
 * @returns A promise that resolves to an object containing the status of the edit operation.
 */
export const editFile = async (
  fileId: string,
  fileData: FileDataProps
): Promise<{ status: number }> => {
  const { updateFile } = getEndpoint();
  const endpoint = `${updateFile}/${fileId}`;

  const { status } = await apiHandler.patch(
    endpoint,
    {
      title: fileData.title,
      alt: fileData.alt,
      link: fileData.link,
    },
    {
      baseURL: moduleBaseURL,
    }
  );

  return { status };
};

/**
 * Delete files with the provided ID's.
 * @param ids - An array of file IDs to delete.
 * @param cancelToken - Optional cancel token to cancel the request.
 * @returns A promise that resolves to an object containing the status of the delete operation.
 */
export const deleteFile = async (ids: string[]) => {
  const { deleteFilesURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.delete(
    endpoint,
    { ids },
    {
      baseURL: moduleBaseURL,
    }
  );
  return { status };
};

export const addFiles = async (
  data: FileBaseDataToSaveProps,
  setUploadPercent?: Dispatch<SetStateAction<number>>,
  xmlHttpRequest?: XMLHttpRequest
): Promise<{
  status: number;
  files: FileProps[];
  errorMessage?: string;
}> => {
  try {
    const response = await uploadWithXML(
      data,
      setUploadPercent,
      xmlHttpRequest
    );
    return { files: response?.files || [], status: response?.status || 502 };
  } catch (error) {
    const errorResponse = error as unknown as {
      status: number;
      errorMessage: string;
    };

    return {
      status: errorResponse.status || 502,
      errorMessage: errorResponse.errorMessage || '',
      files: [],
    };
  }
};

export const uploadWithXML = async (
  data: FileBaseDataToSaveProps,
  setUploadPercent?: Dispatch<SetStateAction<number>>,
  xmlHttpRequest?: XMLHttpRequest
): Promise<{
  status: number;
  files?: FileProps[];
  errorMessage?: string;
}> => {
  return new Promise(async (resolve, reject) => {
    const { addFileURL } = getEndpoint();

    const userToken = getToken() || '';
    const formData = new FormData();
    const endpoint = `${moduleBaseURL}${addFileURL}`;
    const baked = bakeFile(data);

    (Object.keys(baked) as FileDataToSaveKey[])
      .sort((a, b) => {
        if (a === 'files') return 1;
        else if (b === 'files') return -1;
        return 0;
      })
      .forEach((key) => {
        if (key === 'files') {
          if (Array.isArray(baked[key])) {
            baked[key].forEach((item) => {
              formData.append('files', item);
            });
          }
        } else {
          formData.append(key, baked[key]);
        }
      });

    const req = xmlHttpRequest || new XMLHttpRequest();

    req.addEventListener('load', () => {
      if (isSucceed(req.status)) {
        const response = JSON.parse(req.response) as { docs: FileProps[] };

        return resolve({ status: req.status, files: response?.docs || [] });
      } else {
        const response = JSON.parse(req.response) as {
          error: { status: number; message: string };
        };
        return reject({
          status: response.error.status,
          errorMessage: response.error.message,
          files: [],
        });
      }
    });

    req.addEventListener('error', () => reject({ status: req.status }));
    req.onerror = () => reject({ status: req.status });
    if (setUploadPercent) {
      req.upload.addEventListener('progress', (progressEvent) => {
        const uploadPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercent(uploadPercentage);
        // setAppAlert(`درحال آپلود فایل‌ها ${uploadPercentage}%`, 'info');
      });
    }

    req.open('POST', endpoint);
    req.setRequestHeader(getAuthTokenName(), userToken || '');
    req.send(formData);
  });
};
