// Custom Utilities
import { forLoop } from 'core/utilities/helper';
import apiHandler from 'core/utilities/apiHandler';

// Custom Types
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormStepDataProps } from 'features/form/forms/types/item/step';
import type { ApiGetSummaryResponse } from 'core/types/api/hook/response';
import type {
  FormSectionDataProps,
  FormSectionProps,
  FormSectionSummaryProps,
} from 'features/form/forms/types/item/section';

// Custom Endpoinst
import { formSectionsEndpoints as endpoints } from 'features/form/forms/utilities/api/endpoints';

export const addFormSection = async (payload: {
  formId: string;
  stepId: string;
  sortIndex: number;
}): Promise<{ status: number; section?: FormSectionProps }> => {
  const { status, data: response } = await apiHandler.post<{
    docs: { id: string };
  }>(endpoints.add, {
    title: 'بخش جدید',
    formId: payload.formId,
    stepId: payload.stepId,
    sortIndex: payload.sortIndex,
    repeatCount: 0,
    columnCount: 1,
    hideTitle: false,
    hideBorder: false,
    isHidden: false,
    isRepeated: false,
    repeatedRefId: '',
  });

  const section: FormSectionProps = {
    id: response?.docs?.id || '',
    data: {
      title: 'بخش جدید',
      formId: payload.formId,
      stepId: payload.stepId,
      sortIndex: payload.sortIndex,
      repeatCount: 0,
      columnCount: 1,
      hideTitle: false,
      hideBorder: false,
      isHidden: false,
      isRepeated: false,
      repeatedRefId: '',
    },
  };

  return { status, section };
};

/**
 * Edits an existing section of a form.
 *
 * @param {string} sectionId - The ID of the section to be edited.
 * @param {FormSectionDataProps} data - The new data for the form section.
 * @returns {Promise<{ status: number; doc?: FormSectionProps }>} A promise that resolves to an object containing the status and the updated form section data, if available.
 *
 */
export const editFormSection = async (
  sectionId: string,
  data: FormSectionDataProps
): Promise<{ status: number; doc?: FormSectionProps }> => {
  const { status, data: response } = await apiHandler.patch<{
    docs: FormSectionProps;
  }>(endpoints.edit(sectionId), data);

  return { status, doc: response?.docs };
};

/**
 * Updates the sort order of form sections.
 *
 * @param {FormSectionProps[]} sections - An array of form section objects with updated sort indices.
 * @returns {Promise<{ status: number; updatedSections?: FormSectionProps[] }>} A promise that resolves to an object containing the status and optionally the updated form sections.
 *
 */
export const editFormSectionsSort = async (
  sections: FormSectionProps[]
): Promise<{ status: number; docs?: FormSectionProps[] }> => {
  let data: { id: string; sortIndex: number }[] = [];

  forLoop(sections, (section) =>
    data.push({
      id: section.id,
      sortIndex: section.data.sortIndex,
    })
  );

  const { status, data: response } = await apiHandler.patch<{
    docs: { data: FormSectionProps[] };
  }>(endpoints.editDocs(), { sections: data });

  return { status, docs: response?.docs.data };
};

/**
 * Removes a form section by its ID.
 *
 * @param {string} sectionId - The ID of the section to be removed.
 * @returns {Promise<{ status: number; message?: string }>} A promise that resolves to an object containing the status of the removal operation and an optional message.
 *
 */
export const removeFormSection = async (
  sectionId: string
): Promise<{ status: number; message?: string }> => {
  const { status } = await apiHandler.delete(endpoints.removeById(sectionId));

  return { status };
};

/**
 * Copies a form section by its ID.
 *
 * @param {string} sectionId - The ID of the section to be copied.
 * @returns {Promise<{ status: number; doc?: any }>} A promise that resolves to an object containing the status of the copy operation and optionally the ID of the newly created section.
 *
 */
export const copyFormSection = async (
  sectionId: string
): Promise<{
  status: number;
  doc?: {
    id: string;
    data: FormStepDataProps & {
      sections: FormSectionProps[];
      inputs: FormInput[];
    };
  };
}> => {
  const { status, data: response } = await apiHandler.post<{
    docs?: {
      id: string;
      data: FormStepDataProps & {
        sections: FormSectionProps[];
        inputs: FormInput[];
      };
    };
  }>(endpoints.copyById(sectionId));

  return {
    status,
    doc:
      response && response.docs && response.docs.id ? response.docs : undefined,
  };
};

/**
 * Retrieves a summary of form sections by the form ID.
 *
 * @param {string} id - The ID of the form whose sections are to be retrieved.
 * @param {{ isHidden?: boolean }} [options] - Optional parameters for the request.
 * @param {boolean} [options.isHidden] - Optional flag to include hidden sections in the summary.
 * @returns {Promise<ApiGetSummaryResponse<FormSectionSummaryProps>>} A promise that resolves to an object containing the status and a list of form section summaries.
 *
 */
export const getFormSectionsSummary = async (
  id: string,
  options?: { isHidden?: boolean }
): Promise<ApiGetSummaryResponse<FormSectionSummaryProps>> => {
  let endpoint = endpoints.getAll(
    id,
    options && options.isHidden ? { isHidden: 'true' } : undefined
  );

  let list: FormSectionSummaryProps[] = [];

  const { status, data: response } = await apiHandler.get<{
    docs: {
      data: FormSectionProps[];
    };
  }>(endpoint);

  response?.docs?.data?.forEach?.((section) => {
    list.push({
      id: section.id,
      data: { label: section.data.title },
    });
  });

  return {
    status,
    list,
  };
};
