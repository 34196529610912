// Feature Types
import type { StablePatternContentProps } from 'features/appBuilder/patterns/features/stable/types/content';

export const stablePatternContentDefaults: StablePatternContentProps = {
  isActive: true,
  image: {
    isActive: true,
    displayType: 'default',
    imagePosition: 'start',
    file: null,
    imageSize: 224,
    imageType: 'cover',
    padding: '0px',
  },
  titleOne: {
    isActive: true,
    text: '',
    fontFamily: 'vazir',
    fontSize: '18',
    textDirection: 'rtl',
    textAlign: 'start',
    padding: '0px',
    fontColor: '#12252E',
    bgColor: 'rgba(0, 0, 0, 0)',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  description: {
    isActive: true,
    title: '',
    fontFamily: 'vazir',
    fontSize: '18',
    textDirection: 'rtl',
    textAlign: 'start',
    padding: '0px',
    fontColor: '#59666D',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#59666D',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
};
