import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';

// Custom Types
export interface FormPatternButtonSectionItemProps {}
type CollapsedKey =
  | 'section'
  | 'next'
  | 'prev'
  | 'confirm'
  | 'edit'
  | 'view'
  | 'back'
  | 'submit'
  | 'cancel'
  | 'addRepetitive'
  | 'delRepetitive';

type MapButton = {
  key: CollapsedKey;
  label: string;
  palceholder: string;
};

const stepButtons: MapButton[] = [
  { key: 'next', label: 'مرحله بعد', palceholder: 'مرحله بعد' },
  { key: 'prev', label: 'مرحله قبل', palceholder: 'مرحله قبل' },
];

const buttons: MapButton[] = [
  { key: 'confirm', label: 'تایید', palceholder: 'تایید' },
  { key: 'edit', label: 'ویرایش', palceholder: 'ویرایش' },
  { key: 'view', label: 'مشاهده', palceholder: 'مشاهده' },
  { key: 'back', label: 'بازگشت', palceholder: 'بازگشت' },
  { key: 'submit', label: 'ثبت فرم', palceholder: 'ثبت فرم' },
  { key: 'cancel', label: 'لغو تغییرات', palceholder: 'لغو تغییرات' },
  {
    key: 'addRepetitive',
    label: 'افزودن بخش تکرار شونده',
    palceholder: 'افزودن بخش تکرار شونده',
  },
  {
    key: 'delRepetitive',
    label: 'حذف بخش تکرار شونده',
    palceholder: 'حذف بخش تکرار شونده',
  },
];

const FormPatternButtonSection: FC<FormPatternButtonSectionItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    next: false,
    prev: false,
    confirm: false,
    edit: false,
    view: false,
    back: false,
    submit: false,
    cancel: false,
    addRepetitive: false,
    delRepetitive: false,
  });
  const { control } = useFormContext();

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <Controller
      control={control}
      name='variant'
      render={({ field }) => (
        <PatternSectionContainer
          title='دکمه‌ها'
          collapsed={collapsed.section}
          onCollapsedChange={handleCollapseChanged('section')}
          reference='button'
        >
          {stepButtons.map((item, index) =>
            field.value === 0 ? (
              <PatternTitleItem
                title={item.label}
                inputLabel='متن دکمه'
                key={`${item.key}-${item.label}-${index}`}
                collapsed={collapsed[item.key]}
                onCollapsedChange={handleCollapseChanged(item.key)}
                reference={`button.${item.key}`}
                placeholder={item.palceholder}
                include={[
                  'padding',
                  'fontSize',
                  'textDirection',
                  'fontColor',
                  'bgColor',
                  'fontHoverColor',
                  'bgHoverColor',
                ]}
              />
            ) : (
              <PatternEmptyItem
                title={item.key === 'next' ? 'سوال بعد' : 'سوال قبل'}
                key={`${item.key}-${item.label}-${index}`}
                collapsed={collapsed[item.key]}
                onCollapsedChange={handleCollapseChanged(item.key)}
                reference={`button.${item.key}`}
                include={[
                  'padding',
                  'fontSize',
                  'textDirection',
                  'fontColor',
                  'bgColor',
                  'fontHoverColor',
                  'bgHoverColor',
                ]}
              />
            )
          )}
          {buttons.map((item, index) => (
            <PatternTitleItem
              title={item.label}
              inputLabel='متن دکمه'
              key={`${item.key}-${item.label}-${index}`}
              collapsed={collapsed[item.key]}
              onCollapsedChange={handleCollapseChanged(item.key)}
              reference={`button.${item.key}`}
              placeholder={item.palceholder}
              include={[
                'padding',
                'fontSize',
                'textDirection',
                'fontColor',
                'bgColor',
                'fontHoverColor',
                'bgHoverColor',
              ]}
            />
          ))}
        </PatternSectionContainer>
      )}
    />
  );
};

export default FormPatternButtonSection;
