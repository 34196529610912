import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useFormContext,
  Controller,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Core Components
import Box from 'core/components/base/layout/Box';

// Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CancelButton from 'features/form/shared/components/button/Cancel';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Feature Components
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { setAppLoading } from 'core/utilities/helper';

// Context
import { useAppSelector } from 'core/hooks/redux';

// Feature Utilities
import { formsQueryKey } from 'features/form/forms/hooks';
import { getFormsListForProcess } from 'features/form/forms/utilities/api';
import { updateProcessFocus } from 'features/form/processes/store/actions';
import {
  useAddProcessMutation,
  useEditProcessMutation,
} from 'features/form/processes/hooks';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';

// Custom Types
export interface ProcessConfigSectionProps {
  mode?: 'add' | 'edit';
}

const ProcessConfigSection: FC<ProcessConfigSectionProps> = (props) => {
  // Props
  const { mode } = props;

  // Context
  const loading = useSelectLoading();
  const { control, formState, getValues, setValue, setError, handleSubmit } =
    useFormContext<ProcessProps>();

  // Hooks
  const navigate = useNavigate();
  const isFocusedOnConfig = useAppSelector(
    (state) => state.process.currentFocus === 'config'
  );
  const { mutate: handleAddProcess } = useAddProcessMutation({
    autoAlert: { mode: 'create', name: 'فرایند' },
    stayOnLoadingAfterMutate: true,
    onSuccess: ({ processId }) => {
      setTimeout(() => {
        navigate(`/processes/${processId}`, { replace: true });
      }, 2500);
    },
  });
  const { mutate: handleEditProcess } = useEditProcessMutation({
    autoAlert: { mode: 'edit', name: 'فرایند' },
    onSuccess: () => updateProcessFocus(''),
  });

  useUnMount(() => setAppLoading(false));

  // Utilities
  const handleCancel = () => {
    if (mode === 'add') navigate(-1);
    else {
      // Reset Data
      updateProcessFocus('');
    }
  };

  const handleFormSubmit: SubmitHandler<ProcessProps> = async (process) => {
    if (mode === 'add') handleAddProcess(process.data);
    else handleEditProcess(process);
  };

  return (
    <RoundPaper sx={{ p: '1.5rem' }}>
      <BodyOne>تنظیمات فرایند</BodyOne>
      <Box sx={{ mt: '1rem', mb: '2rem' }}>
        <InputWrapper sx={{ width: '50%' }} label='انتخاب فرم' required>
          <Controller
            control={control}
            name='data.formId'
            render={({ field }) => (
              <SingleAutocomplete
                disabled={loading || mode === 'edit'}
                readOnly={mode === 'edit'}
                placeholder={mode === 'edit' ? getValues('data.formTitle') : ''}
                error={formState.errors.data?.formId?.message ? true : false}
                helperText={formState.errors.data?.formId?.message}
                apiHandler={getFormsListForProcess}
                queryKey={formsQueryKey}
                defaultValue={field.value}
                onChange={(id, opt) => {
                  updateProcessFocus('config');
                  setValue(field.name, id);
                  setValue('data.formTitle', opt ? opt.data.title : '');
                  setError(field.name, {
                    message: id ? undefined : 'انتخاب فرم الزامی است.',
                  });
                }}
              />
            )}
          />
        </InputWrapper>
      </Box>
      {(mode === 'add' || (mode === 'edit' && isFocusedOnConfig)) && (
        <Box sx={{ display: 'flex', gap: 1, width: 'fit-content', ml: 'auto' }}>
          <CancelButton
            disabled={loading || (mode === 'edit' && !isFocusedOnConfig)}
            onClick={handleCancel}
            sx={{ p: '0.5rem', borderRadius: '0.5rem' }}
            variant='outlined'
          />
          <Controller
            control={control}
            name='data.formId'
            render={({ field }) => (
              <SaveLoadingButton
                loading={loading}
                loadingPosition='start'
                onClick={handleSubmit(handleFormSubmit)}
                disabled={
                  !field.value || (mode === 'edit' && !isFocusedOnConfig)
                }
                sx={{ p: '0.5rem', borderRadius: '0.5rem' }}
                variant='outlined'
              />
            )}
          />
        </Box>
      )}
    </RoundPaper>
  );
};

export default ProcessConfigSection;
