import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternColorItem from 'features/appBuilder/patterns/core/components/shared/items/Color';

// Custom Types
export interface FormPatternBodySectionItemProps {}
type CollapsedKey = 'section' | 'bodySection' | 'field';

const FormPatternBodySection: FC<FormPatternBodySectionItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    bodySection: false,
    field: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='بخش‌ها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='body'
    >
      <PatternColorItem
        title='بخش'
        collapsed={collapsed.bodySection}
        onCollapsedChange={handleCollapseChanged('bodySection')}
        reference='body.section'
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
        ]}
        colorControllers={[
          { keyName: 'borderColor', title: 'رنگ بوردر' },
          { keyName: 'bgColor', title: 'بکگراند بخش' },
        ]}
      />
      <PatternColorItem
        title='فیلدها'
        collapsed={collapsed.field}
        onCollapsedChange={handleCollapseChanged('field')}
        reference='body.field'
        include={['padding', 'fontSize', 'textDirection', 'fontColor']}
        colorControllers={[
          { keyName: 'borderColor', title: 'رنگ بوردر' },
          { keyName: 'iconColor', title: 'رنگ آیکن' },
        ]}
      />
    </PatternSectionContainer>
  );
};

export default FormPatternBodySection;
