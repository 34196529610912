import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTabItem from 'features/appBuilder/patterns/core/components/shared/items/Tab';

// Custom Types
export interface SliderPatternBodySectionProps {}
type CollapsedKey = 'section' | 0;

const SliderPatternBodySection: FC<SliderPatternBodySectionProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    0: false,
  });
  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='محتوای اسلایدر'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='body'
    >
      <PatternTabItem
        reference={`body.tabs.${0}`}
        title='تب'
        collapsed={collapsed[0]}
        onCollapsedChange={handleCollapseChanged(0)}
        include={['padding']}
        hideTitle
        hideArchive
      />
    </PatternSectionContainer>
  );
};

export default SliderPatternBodySection;
