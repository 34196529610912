// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { bakeAudience } from 'features/audience/audiences/utilities/api';
import { getRoundedCurrentDate } from 'core/utilities/helper/date';
import { fileDownload } from 'core/utilities/helper/file';
import { getLocaleDate } from 'core/utilities/helper/date';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import {
  transformAudience,
  transformAudiences,
} from 'features/audience/audiences/utilities';

// Custom Types
import type { ApiAudienceProps } from 'features/audience/audiences/types/api';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  AudienceDataProps,
  AudienceProps,
  AudienceSummaryProps,
} from 'features/audience/audiences/types';

/**
 * Fetches audience details by its ID from the API.
 *
 * @param {string} audienceId - The ID of the audience to retrieve.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @returns {Promise<ApiGetDocResponse<AudienceProps>>} A promise that resolves to an object containing the status and the audience details.
 */
export const getAudience = async (
  audienceId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<AudienceProps>> => {
  const { getAudienceURL } = getEndpoint();
  const endpoint = `${getAudienceURL}/${audienceId}`;
  const { status, data: response } = await apiHandler.get<{
    docs: ApiAudienceProps;
  }>(endpoint, {
    signal,
  });
  return {
    status,
    doc: response?.docs ? transformAudience(response?.docs) : undefined,
  };
};

/**
 * Fetches a list of audiences from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @param {Record<string, any>} [query] - An optional object containing query parameters for filtering the audience list.
 * @returns {Promise<ApiGetDocsResponse<AudienceProps>>} A promise that resolves to an object containing the status, list of audiences, and pagination information.
 */
export const getAudiences = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<AudienceProps>> => {
  const { getAudiencesURL } = getEndpoint();
  const { status, data: response } = await apiHandler.get<{
    docs: { data: ApiAudienceProps[]; paginate: ApiPaginationProps };
  }>(getUrlWithQueryString(getAudiencesURL, query), {
    signal,
  });
  const audiences: AudienceProps[] = transformAudiences(
    response?.docs?.data || []
  );
  const page = {
    current: response?.docs.paginate.page || 1,
    size: response?.docs.paginate.limit || 20,
    totalDocs: response?.docs.paginate.totalDocs || 0,
  };
  return { status, list: audiences, page };
};

/**
 * Adds a new audience to the API.
 *
 * @param {{ phoneNumbers: string[], jobTitle: string, audienceGroups: string[] }} payload - The audience data to be added.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const addAudience = async (payload: {
  phoneNumbers: string[];
  jobTitle: string;
  audienceGroups: string[];
}): Promise<{ status: number }> => {
  const { addAudienceURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.post(endpoint, {
    phones: payload.phoneNumbers,
    jobTitle: payload.jobTitle,
    groups: payload.audienceGroups,
  });
  return { status };
};

/**
 * Edits an existing audience in the API.
 *
 * @param {string} id - The ID of the audience to edit.
 * @param {AudienceDataProps} data - The updated audience data.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const editAudience = async (
  id: string,
  data: AudienceDataProps
): Promise<{ status: number }> => {
  const { editAudienceURL } = getEndpoint();
  const endpoint = `${editAudienceURL}/${id}`;
  const baked = bakeAudience({ id, data });
  const { status } = await apiHandler.patch(endpoint, {
    ...baked.data,
    groups: baked.data.groups.map((gp) => gp.id) || [],
  });
  return { status };
};

/**
 * Updates the status of an audience (enabled/disabled).
 *
 * @param {string} audienceId - The ID of the audience whose status is to be updated.
 * @param {boolean} enabled - The new status of the audience (true for enabled, false for disabled).
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const updateAudienceStatus = async (
  audienceId: string,
  enabled: boolean
): Promise<{ status: number }> => {
  const { editAudienceURL } = getEndpoint();
  const endpoint = `${editAudienceURL}/${audienceId}`;
  const { status } = await apiHandler.patch(endpoint, {
    id: audienceId,
    isEnabled: enabled,
  });
  return { status };
};

/**
 * Deletes audiences from the API.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be deleted.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const deleteAudiences = async (
  audienceIds: string[]
): Promise<{ status: number }> => {
  const { deleteAudiencesURL: endpoint } = getEndpoint();
  const body = { ids: audienceIds };
  const { status } = await apiHandler.put(endpoint, body);
  return { status };
};

/**
 * Edits the job title of multiple audiences.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be updated.
 * @param {string} jobTitle - The new job title to be set for the audiences.
 * @returns {Promise<{ status: number, audiences: AudienceProps[], totalDocs: number }>} A promise that resolves to an object containing the status, updated audiences, and total documents.
 */
export const editAudiencesJobTitle = async (
  audienceIds: string[],
  jobTitle: string
): Promise<{
  status: number;
  audiences: AudienceProps[];
  totalDocs: number;
}> => {
  let { changeAudienceJobTitleURL: endpoint } = getEndpoint();
  const body = {
    ids: audienceIds,
    jobTitle,
  };
  const { status } = await apiHandler.put(endpoint, body);
  return { status, audiences: [], totalDocs: 0 };
};

/**
 * Adds audiences to specified groups.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be added to groups.
 * @param {string[]} groupsIds - An array of group IDs to which audiences will be added.
 * @returns {Promise<{ status: number, audiences: AudienceProps[], totalDocs: number }>} A promise that resolves to an object containing the status, audiences, and total documents.
 */
export const addAudiencesToGroups = async (
  audienceIds: string[],
  groupsIds: string[]
): Promise<{
  status: number;
  audiences: AudienceProps[];
  totalDocs: number;
}> => {
  let { addAudienceToGroupURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.put(endpoint, {
    ids: audienceIds,
    groupIds: groupsIds,
  });
  return { status, audiences: [], totalDocs: 0 };
};

/**
 * Removes audiences from specified groups.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be removed from groups.
 * @param {string[]} groupsIds - An array of group IDs from which audiences will be removed.
 * @returns {Promise<{ status: number, audiences: AudienceProps[], totalDocs: number }>} A promise that resolves to an object containing the status, audiences, and total documents.
 */
export const removeAudiencesFromGroups = async (
  audienceIds: string[],
  groupsIds: string[]
): Promise<{
  status: number;
  audiences: AudienceProps[];
  totalDocs: number;
}> => {
  let { removeAudienceFromGroupURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.put(endpoint, {
    ids: audienceIds,
    groupIds: groupsIds,
  });
  return { status, audiences: [], totalDocs: 0 };
};

/**
 * Generates an Excel report for specified audiences.
 *
 * @param {string[]} audienceIds - An array of audience IDs for which the report is to be generated.
 * @returns {Promise<{ status: number, audiences: AudienceProps[], totalDocs: number }>} A promise that resolves to an object containing the status, audiences, and total documents.
 */
export const getAudiencesExcelReport = async (
  audienceIds: string[]
): Promise<{
  status: number;
  audiences: AudienceProps[];
  totalDocs: number;
}> => {
  let { getAudienceExcelURL: endpoint } = getEndpoint();
  const body = {
    ids: audienceIds,
  };
  const { status, data } = await apiHandler.put(endpoint, body, {
    headers: {
      'Content-Types': 'application/json',
      responseType: 'blob',
    },
  });
  const fileName = `خروجی_مخاطبان_${getLocaleDate(
    getRoundedCurrentDate().toISOString()
  )}.xlsx`;
  fileDownload(data, fileName);
  return { status, audiences: [], totalDocs: 0 };
};

/**
 * Adds audiences to a specific access.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be added.
 * @param {string} accessId - The ID of the access to which audiences will be added.
 * @returns {Promise<{ status: number, failed: string[], passed: string[] }>} A promise that resolves to an object containing the status and lists of failed and passed audiences.
 */
export const addAudiencesToAccess = async (
  audienceIds: string[],
  accessId: string
): Promise<{
  status: number;
  failed: string[];
  passed: string[];
}> => {
  const endpoint = `${getEndpoint().addAudienceToAccessURL}/${accessId}`;
  const { status, data: response } = await apiHandler.post(endpoint, {
    audiences: audienceIds,
  });
  const {
    docs: { failed, passed },
  } = response as { docs: { failed: string[]; passed: string[] } };
  return { status, failed, passed };
};

/**
 * Removes audiences from access.
 *
 * @param {string[]} audienceIds - An array of audience IDs to be removed from access.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const removeAudienceFromAccess = async (
  audienceIds: string[]
): Promise<{
  status: number;
}> => {
  const endpoint = getEndpoint().removeAudienceAccessURL;
  const { status } = await apiHandler.post(endpoint, {
    audiences: audienceIds,
  });
  return { status };
};

/**
 * Fetches a summary list of audiences from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<{ status: number; list: AudienceSummaryProps[] }>}
 *          A promise that resolves to an object containing the HTTP status and a list of audience summaries.
 */
export const getAudiencesSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<AudienceSummaryProps>> => {
  const { getAudienceListURL: endpoint } = getEndpoint();

  const { status, data } = await apiHandler.get<{
    docs: AudienceSummaryProps[];
  }>(endpoint, {
    signal,
  });

  return { status, list: data?.docs || [] };
};
