import { useState, type FC, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Custom Section Component
import TablePatternPreview from 'features/appBuilder/patterns/features/table/components/PatternPreview';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Icon Components
import PrintIcon from 'core/components/icons/Print';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { getPatternPreview } from 'features/appBuilder/patterns/utilities/api';
import { getAudiencesSummaryList } from 'features/audience/audiences/utilities/api';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import { setAppAlert } from 'core/utilities/helper';
import { setAppLoading } from 'core/utilities/helper';
import { audiencesQueryKey } from 'features/audience/audiences/hooks';

// Custom Types
import type { AudienceSummaryProps } from 'features/audience/audiences/types';
import type { TablePatternProps } from 'features/appBuilder/patterns/types';

interface TablePreviewProps {}

const TablePreview: FC<TablePreviewProps> = () => {
  // States
  const [value, setValue] = useState<AudienceSummaryProps | null>(null);
  const [preview, setPreview] = useState<TablePatternProps | null>(null);

  // Hooks
  const loading = useSelectLoading();
  const navigate = useNavigate();
  const { id: patternId } = useParams();

  const handleSetPreview = useCallback(async () => {
    if (patternId && value) {
      setAppLoading(true);

      const { status, previewData } = await getPatternPreview(
        patternId,
        value.id
      );

      if ([200, 201].includes(status) && previewData) {
        setPreview(previewData);
      } else {
        setAppAlert('خطا در دریافت اطلاعات پیش‌نمایش');
      }

      setAppLoading(false);
    }
  }, [patternId, value]);

  useEffect(() => {
    if (value) {
      handleSetPreview();
    }
  }, [value, handleSetPreview]);

  // Utilities
  const navigateBack = () => navigate(-1);

  const handlePrint = () => {
    if (window) window.print();
  };

  return (
    <>
      <PageHeadConfigs pageTitle='پیش نمایش تیبل' />
      <PageHeroBox>
        <PageTitle>پیش‌نمایش</PageTitle>
        <RowStack>
          <SingleAutocomplete
            sx={{
              minWidth: '12rem',
              '.MuiOutlinedInput-root': {
                maxHeight: '2.5rem',
                paddingTop: '0.313rem',
              },
            }}
            ListboxProps={{ sx: { ...customRoundedScrollbarStyles } }}
            apiHandler={getAudiencesSummaryList}
            queryKey={audiencesQueryKey}
            onChange={(_, audience: AudienceSummaryProps) => setValue(audience)}
            getOptionLabel={(option: AudienceSummaryProps) =>
              (option.data.firstName || '-') +
              ' ' +
              (option.data.lastName || '-') +
              ' ' +
              `(${
                option.data.phoneNumber
                  ? option.data.phoneNumber?.toPersian()
                  : '-'
              })`
            }
          />

          <IconButton
            disabled={!value || loading}
            disableRipple
            onClick={handlePrint}
            sx={{
              border: '1px solid',
              borderColor: 'info.light',
              color: 'info.light',
              borderRadius: '0.25rem',
              width: '2.5rem',
              height: '2.5rem',
            }}
          >
            <PrintIcon color='inherit' />
          </IconButton>
          <Button
            onClick={navigateBack}
            sx={{ minWidth: '8rem', height: '2.5rem' }}
            variant='outlined'
            color='info'
          >
            بازگشت
          </Button>
        </RowStack>
      </PageHeroBox>
      <ColumnStack>
        {preview && <TablePatternPreview preview={preview} />}
      </ColumnStack>
    </>
  );
};

export default TablePreview;
