// Feature Types
import type { PatternMenuContainerProps } from 'features/appBuilder/patterns/core/types/item/menu';
import type { FooterPatternMenuProps } from 'features/appBuilder/patterns/features/footer/types/menu';

const vertiaclDefaults: PatternMenuContainerProps = {
  containerId: '',
  containerTitle: '',
  isActive: true,
  itemCount: 6,
  padding: '0px',
  fontSize: '14',
  textDirection: 'rtl',
  textAlign: 'start',
  itemImageSize: 0,
  titleFontSize: '18',
  color: '#7B808C',
  bgColor: 'rgba(0, 0, 0, 0)',
  hoverColor: '#7B808C',
  activeColor: '#12252E',
  activeBgColor: 'rgba(0, 0, 0, 0)',
  titleBgColor: 'rgba(0, 0, 0, 0)',
  titleColor: '#12252E',
  bgHoverColor: 'rgba(0, 0, 0, 0)',
  subMenuColor: '#59666D',
  subMenuHoverColor: '#59666D',
  subMenuBg: '#C1C8D3',
  subMenuBgHoverColor: '#C1C8D3',
};

export const footerPatternMenuDefaults: FooterPatternMenuProps = {
  isActive: true,
  verticalOne: vertiaclDefaults,
  verticalTwo: vertiaclDefaults,
  verticalThree: vertiaclDefaults,
  horizontalOne: {
    containerId: '',
    containerTitle: '',
    isActive: true,
    itemCount: 6,
    subMenuBg: '#C1C8D3',
    hoverColor: '#59666D',
    padding: '0px',
    fontSize: '14',
    textDirection: 'rtl',
    textAlign: 'center',
    color: '#59666D',
    bgColor: 'rgba(0, 0, 0, 0)',
    activeColor: '#0091FF',
    activeBgColor: 'rgba(0, 0, 0, 0)',
    itemImageSize: 32,
    titleBgColor: 'rgba(0, 0, 0, 0)',
    titleColor: 'rgba(0, 0, 0, 0)',
    titleFontSize: '18',
    subMenuColor: 'rgba(0, 0, 0, 0)',
    subMenuBgHoverColor: 'rgba(0, 0, 0, 0)',
    subMenuHoverColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
};
