import cloneDeep from 'lodash/cloneDeep';

// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { forLoop } from 'core/utilities/helper';

// Custom Endpoints
import { formInputsEndpoints as endpoints } from 'features/form/forms/utilities/api/endpoints';

// Custom Store
import store from 'core/store';

// Custom Types
import type { ApiGetDocsResponse } from 'core/types/api/hook/response';
import type {
  FormInput,
  FormInputSummaryProps,
} from 'features/form/forms/types/item/input';

/**
 * Adds or edits form inputs.
 *
 * @param {FormInput[]} inputs - An array of form input objects to be added or edited.
 * @returns {Promise<{ status: number; updatedInputs?: FormInput[] }>} A promise that resolves to an object containing the status of the operation and optionally the updated form inputs.
 *
 */
export const addAndEditFormInputs = async (
  inputs: FormInput[]
): Promise<{ status: number; docs?: FormInput[] }> => {
  let body: {
    inputs: FormInput[];
    formId: string;
    stepId: string;
    sectionId: string;
  } = {
    inputs: [],
    formId: '',
    stepId: '',
    sectionId: '',
  };

  const inputsToSave: FormInput[] = [];
  forLoop(inputs, (input) => {
    const clonedInput = cloneDeep(input);

    if ('options' in clonedInput.data) {
      const opts: any[] = [];

      clonedInput.data.options.forEach((opt) => opts.push(opt.data));

      clonedInput.data.options = opts;
    }

    inputsToSave.push(clonedInput);
  });

  if (inputs.length > 0) {
    body.formId = inputs[0].data.formId;
    body.stepId = inputs[0].data.stepId;
    body.sectionId = inputs[0].data.sectionId;
  }

  body.inputs = inputsToSave;

  const { status, data: response } = await apiHandler.patch<{
    docs: FormInput[];
  }>(endpoints.edit(), body);

  return { status, docs: response?.docs };
};

export const getInputsList = async (signal?: AbortSignal) => {
  const formId = store.getState().form.formId;
  const endpoint = endpoints.getSummaryListById(formId);

  let list: FormInputSummaryProps[] = [];

  const { status, data } = await apiHandler.get<{
    docs?: FormInputSummaryProps[];
  }>(endpoint, { signal });

  if (data && data.docs && data.docs.length > 0) {
    list = data.docs;
  }

  return { status, list: list };
};

/**
 * Fetches a list of form input summaries for a specific audience based on the current form ID.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<FormInputSummaryProps>>}
 *          A promise that resolves to an object containing the HTTP status and a list of filtered form input summaries.
 */
export const getInputsListOfAudiences = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<FormInputSummaryProps>> => {
  const formId = store.getState().form.formId;
  const endpoint = endpoints.getSummaryListById(formId);

  let list: FormInputSummaryProps[] = [];

  const { status, data } = await apiHandler.get<{
    docs?: FormInputSummaryProps[];
  }>(endpoint, {
    signal,
  });

  if (data && data.docs && data.docs.length > 0) {
    list = data.docs.filter((inp) =>
      ['text', 'number', 'mobile', 'nationalId', 'email'].includes(
        inp.data.type
      )
    );
  }

  return { status, list };
};

/**
 * Retrieves a list of form inputs and their corresponding values for a specific form.
 *
 * @param {string} formId - The ID of the form for which to retrieve the inputs and values.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<FormInputSummaryProps>>} A promise that resolves to an object containing the status and a list of form input summaries.
 *
 */
export const getInputsAndValuesList = async (
  formId: string,
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<FormInputSummaryProps>> => {
  const endpoint = endpoints.getSummaryListById(formId);

  const { status, data } = await apiHandler.get<{
    docs: FormInputSummaryProps[];
  }>(endpoint, { signal });

  return { status, list: data?.docs || [] };
};

/**
 * Removes form input elements by their provided IDs.
 * @param {string[]} ids - An array of IDs representing the form input elements to remove.
 * @returns {Promise<{status:number;}>} A promise that resolves to response status.
 */
export const removeFormInputs = async (
  ids: string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(endpoints.remove, { ids });

  return { status };
};
