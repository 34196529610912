// Types
import type {
  PageProps,
  PagePropsStateHandler,
} from 'core/types/shared/pagination';

export const initialPageProps: PageProps = {
  current: 1,
  size: 20,
  totalDocs: 0,
};
/**
 * Sets the current page number in the state.
 *
 * @param {number} current - The current page number.
 * @param {PageProps} state - The state object.
 * @param {PagePropsStateHandler} stateHandler - The state handler function.
 */
export const setCurrentPage = (
  current: number,
  state: PageProps,
  stateHandler: PagePropsStateHandler
) => {
  stateHandler((state) => ({
    ...state,
    current: current,
  }));
};

/**
 * Sets the page size in the state and resets the current page number to 1.
 *
 * @param {number} size - The page size.
 * @param {PageProps} state - The state object.
 * @param {PagePropsStateHandler} stateHandler - The state handler function.
 */
export const setPageSize = (
  size: number,
  state: PageProps,
  stateHandler: PagePropsStateHandler
) => {
  stateHandler((state) => ({
    ...state,
    current: 1,
    size: size,
  }));
};

/**
 * Sets the total number of documents in the state.
 *
 * @param {number} totalDocs - The total number of documents.
 * @param {PageProps} state - The state object.
 * @param {PagePropsStateHandler} stateHandler - The state handler function.
 */
export const setPageTotalDocs = (
  totalDocs: number,
  state: PageProps,
  stateHandler: PagePropsStateHandler
) => {
  stateHandler((state) => ({
    ...state,
    totalDocs: totalDocs,
  }));
};
