// Feature Types
import type { SliderPatternConfigProps } from 'features/appBuilder/patterns/features/slider/types/config';

export const sliderPatterConfigDefaults: SliderPatternConfigProps = {
  isActive: true,
  title: {
    isActive: true,
    padding: '0px',
    fontSize: '20',
    textDirection: 'rtl',
    textAlign: 'center',
    lineClamp: 1,
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  body: {
    isActive: true,
    padding: '0px',
    fontSize: '18',
    textDirection: 'rtl',
    textAlign: 'center',
    lineClamp: 4,
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  image: {
    isActive: true,
    displayType: 'default',
    imageSize: 224,
    objectFit: 'cover',
    padding: '0px',
    height: 224,
    width: 224,
    position: 'afterTitle',
  },
  display: {
    isActive: true,
    align: 'start',
    padding: '0px',
    cardBg: '#FFFFFF',
    cardBgHover: '#FFFFFF',
  },
};
