import { useEffect, useCallback, type FC, useState, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import type { SxProps } from '@mui/material';

// Core Components
import Button from 'core/components/base/inputs/Button';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';
import CheckmarkFilledIcon from 'core/components/icons/CheckmarkFilled';
import QuestionMarkFilledIcon from 'core/components/icons/QuestionMarkFilled';
import PauseFilledIcon from 'core/components/icons/PauseFilledIcon';

// Core Utilities
import useBoolean from 'core/hooks/useBoolean';

// Feature Utilities
import { getProcessItems } from 'features/form/processes/utilities/api';
import { isSucceed, setAppAlert } from 'core/utilities/helper';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type { ProcessItemSummaryProps } from 'features/form/processes/types/list/item';
import type { EvaluationQuestionOptionStatus } from 'features/form/processes/types/details/evaluation/questionOption';

// Custom Types
export interface ProcessItemStatusSelectProps {
  currentItemId?: string;
  label?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (
    status: EvaluationQuestionOptionStatus,
    optId: string,
    optTitle: string
  ) => void;
  sx?: SxProps;
}

const ProcessItemStatusSelect: FC<ProcessItemStatusSelectProps> = (props) => {
  // Props
  const {
    currentItemId,
    defaultValue,
    disabled,
    label = 'پایان',
    sx,
    onChange,
  } = props;

  // Hooks
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [list, setList] = useState<ProcessItemSummaryProps[]>([]);
  const {
    state: open,
    setTrue: handleOpen,
    setFalse: handleClose,
    toggle: handleToggle,
  } = useBoolean();

  const { getValues } = useFormContext<ProcessProps>();
  const id = getValues('id');

  const handleProcessItems = useCallback(async () => {
    if (id && open) {
      const { status, docs } = await getProcessItems(id);

      if (isSucceed(status)) {
        const filtered = docs.filter((doc) => doc.id !== currentItemId);
        setList(filtered);
      } else {
        setAppAlert('خطا در دریافت اطلاعات فرایند');
      }
    }
  }, [id, open]);

  useEffect(() => {
    if (open) handleProcessItems();
  }, [open]);

  return (
    <>
      <Button
        disabled={disabled}
        variant='contained'
        sx={{
          bgcolor: '#F5F5F5',
          flexShrink: 0,
          color: 'text.secondary',
          width: '112px',
          maxWidth: '112px',
          height: '2rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          px: '0.5rem',
          ':hover': { bgcolor: '#F5F5F5' },
          ...sx,
        }}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
          handleToggle();
        }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        MenuListProps={{
          sx: {
            bgcolor: '#F5F5F5',
            width: '245px',
            mt: 0.5,
            p: 0,
            borderRadius: 1,
            boxShadow: 'none',
          },
        }}
        sx={{
          boxShadow: '0px 8px 6px 0px #0000001A',
        }}
      >
        {list.map((item) => (
          <MenuItem
            disabled={defaultValue === item.id}
            key={`process-item-option-${item.id}`}
            sx={{ color: 'info.main', gap: 0.5 }}
            onClick={() => {
              if (onChange) onChange('linkedTo', item.id, item.data.title);
              handleClose();
            }}
          >
            {item.data.type === 'evaluation' ? (
              <CheckmarkFilledIcon />
            ) : (
              <QuestionMarkFilledIcon />
            )}{' '}
            {item.data.title}
          </MenuItem>
        ))}
        <MenuItem
          disabled={defaultValue === 'end'}
          onClick={() => {
            if (onChange) onChange('end', '', 'پایان');
            handleClose();
          }}
          sx={{ color: 'info.main', gap: 0.5 }}
        >
          <PauseFilledIcon fontSize='small' /> پایان
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProcessItemStatusSelect;
