import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternBannerItem from 'features/appBuilder/patterns/core/components/shared/items/Banner';

// Custom Types
export interface FooterPatternBannerItemProps {}
type CollapsedKey = 'section' | 'one';

const FooterPatternBannerSection: FC<FooterPatternBannerItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    one: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='بنرها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='banner'
    >
      <PatternBannerItem
        reference='banner.one'
        title='شبکه اجتماعی'
        collapsed={collapsed.one}
        onCollapsedChange={handleCollapseChanged('one')}
        include={['padding', 'textAlign']}
      />
    </PatternSectionContainer>
  );
};

export default FooterPatternBannerSection;
