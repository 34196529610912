// Custom Utilitis
import apiHandler from 'core/utilities/apiHandler';
import { profileEndpoints as endpoints } from 'features/manager/auth/utilities/shared/api/endpoints';

// Custom Types
import type { ApiGetDocResponse } from 'core/types/api/hook/response';
import type {
  ManagerDataProps,
  ManagerProps,
} from 'features/manager/managers/types';

/**
 * Edits a manager profile data using the provided data.
 * @param {string} id - The id of the manager to update.
 * @param {ManagerDataProps} data - The updated data for the manager.
 * @returns {Promise<{ status: number; doc?: ManagerProps }>} - A promise that resolves to an object containing the status and manager (if available).
 */
export const editProfile = async (
  id: string,
  data: ManagerDataProps
): Promise<{ status: number; doc?: ManagerProps }> => {
  const { status, data: response } = await apiHandler.patch<{
    docs: ManagerProps;
  }>(endpoints.edit, data);

  return { status, doc: response?.docs };
};

/**
 * Fetches the profile of a manager from the API.
 *
 * @param {string} [_] - Optional parameter to satisfy the useDoc hook requirement; does not affect functionality.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @returns {Promise<ApiGetDocResponse<ManagerProps>>} A promise that resolves to an object containing the status and the manager's profile document.
 */
export const getProfile = async (
  _?: string, // I add this because we can't use 'useDoc' hook without this. this argument won't do anything
  signal?: AbortSignal
): Promise<ApiGetDocResponse<ManagerProps>> => {
  const { status, data } = await apiHandler.get<{ docs: ManagerProps }>(
    endpoints.get,
    { signal }
  );
  return { status, doc: data?.docs };
};
