// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';

// Custom Endpoinst
import { formSettingsEndpoints as endpoints } from 'features/form/forms/utilities/api/endpoints';

// Custom Types
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  FormListItemProps,
  FormSummaryProps,
} from 'features/form/forms/types/list';
import type {
  FormDetailsSummaryProps,
  FormProps,
  FormSettingsDataProps,
} from 'features/form/forms/types/item';

/**
 * Adds a new form with the specified settings.
 *
 * @param {FormSettingsDataProps} form - The settings for the form to be added.
 * @returns {Promise<{ status: number; doc?: FormProps }>} The status of the operation and the details of the added form.
 */
export const addForm = async (
  form: FormSettingsDataProps
): Promise<{ status: number; doc?: FormProps }> => {
  if (!form.publishDate) {
    form.publishDate = '';
  }
  if (!form.expireDate) {
    form.expireDate = '';
  }
  const { status, data } = await apiHandler.post<{
    status: number;
    docs: FormProps;
  }>(endpoints.add, form);
  return { status, doc: data && data.docs ? data.docs : undefined };
};

/**
 * Edits an existing form with the specified settings.
 *
 * @param {{ id: string; data: FormSettingsDataProps }} payload - The form object containing the ID and data settings for the form to be edited.
 * @returns {Promise<{ status: number; doc?: FormProps }>} A promise that resolves to an object containing the status and the updated form document, if available.
 *
 */
export const editForm = async (
  formId: string,
  payload: FormSettingsDataProps
): Promise<{ status: number; doc?: FormProps }> => {
  if (!payload.publishDate) {
    payload.publishDate = '';
  }

  if (!payload.expireDate) {
    payload.expireDate = '';
  }

  const { status, data } = await apiHandler.patch<{
    status: number;
    docs?: FormProps;
  }>(endpoints.edit(formId), payload);

  return {
    status,
    doc:
      data && 'docs' in data && typeof data.docs === 'object'
        ? data.docs
        : undefined,
  };
};

/**
 * Retrieves a form by its ID.
 *
 * @param {string} formId - The ID of the form to retrieve.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocResponse<FormProps>>} The status of the operation and the retrieved form details.
 */
export const getForm = async (
  formId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<FormProps>> => {
  const { status, data } = await apiHandler.get<{ docs: FormProps }>(
    endpoints.getById(formId),
    {
      signal,
    }
  );
  return { status, doc: data?.docs };
};

/**
 * Retrieves a list of forms with optional pagination and filtering.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [query] - An optional query object for filtering the forms.
 * @returns {Promise<ApiGetDocsResponse<FormListItemProps>>} The status of the operation, a list of forms, and pagination details.
 */
export const getForms = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<FormListItemProps>> => {
  const { status, data } = await apiHandler.get<{
    docs: { data: FormListItemProps[]; paginate: ApiPaginationProps };
  }>(endpoints.getList(query), {
    signal,
  });
  const page = {
    current: data?.docs?.paginate?.page || 1,
    size: data?.docs?.paginate?.limit || 20,
    totalDocs: data?.docs?.paginate?.totalDocs || 0,
  };
  const list = data?.docs?.data || [];
  return { status, list, page };
};

/**
 * Removes a form by its ID.
 *
 * @param {string} id - The ID of the form to remove.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the removal operation.
 *
 */
export const removeForm = async (id: string): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(endpoints.removeById(id));

  return { status };
};

/**
 * Retrieves a summary list of forms.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<FormSummaryProps>>} The status of the operation and the list of form summaries.
 */
export const getFormsList = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<FormSummaryProps>> => {
  const endpoint = endpoints.getSummaryList + '?limit=210';
  const { status, data } = await apiHandler.get<{
    docs: FormSummaryProps[];
  }>(endpoint, {
    signal,
  });
  return { status, list: data?.docs || [] };
};

/**
 * Fetches a list of forms that do not have an associated process.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<FormSummaryProps>>}
 *          A promise that resolves to an object containing the HTTP status and a list of form summaries without processes.
 */
export const getFormsListForProcess = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<FormSummaryProps>> => {
  const endpoint = endpoints.getSummaryList + '?limit=210';

  const { status, data } = await apiHandler.get<{
    docs: FormSummaryProps[];
  }>(endpoint, {
    signal,
  });

  return {
    status,
    list: data?.docs?.filter?.((doc) => !doc.data.hasProcess) || [],
  };
};

/**
 * Retrieves the summary details of a form by its ID.
 *
 * @param {string} id - The ID of the form whose details are to be retrieved.
 * @returns {Promise<ApiGetDocResponse<FormDetailsSummaryProps>>} A promise that resolves to an object containing the status and the summary details of the form.
 *
 */
export const getFormDetailsSummary = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<FormDetailsSummaryProps>> => {
  const endpoint = endpoints.getDocSummary(id);

  const { status, data } = await apiHandler.get<{
    docs: FormDetailsSummaryProps;
  }>(endpoint, { signal });

  return { status, doc: data?.docs };
};
