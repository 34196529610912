import * as React from 'react';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';

// Custom Types
import type { TextFieldProps } from 'core/components/base/inputs/TextField';
type TextFieldNumberProps = TextFieldProps;

const TextFieldNumber: React.FC<TextFieldNumberProps> = React.forwardRef(
  (props, ref) => {
    const { variant = 'outlined', InputProps, ...otherProps } = props;

    return (
      <TextField
        variant={variant}
        InputProps={{
          sx: {
            '& input[type=number]': {
              mozAppearance: 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              webkitAppearance: 'none',
              margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              webkitAppearance: 'none',
              margin: 0,
            },
            textAlign: 'center',
            ...InputProps?.sx,
          },
          autoComplete: 'off',
          ...InputProps,
        }}
        type='number'
        {...otherProps}
      />
    );
  }
);

export default TextFieldNumber;
