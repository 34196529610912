import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternImageItem from 'features/appBuilder/patterns/core/components/shared/items/Image';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';
import PatternEditorDescriptionItem from 'features/appBuilder/patterns/core/components/shared/items/EditorDescription';

// Custom Types
export interface StablePatternContentItemProps {}
type CollapsedKey = 'section' | 'image' | 'titleOne' | 'description';

const StablePatternContentSection: FC<StablePatternContentItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    image: false,
    titleOne: false,
    description: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='محتوای استیبل'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='content'
    >
      <PatternImageItem
        reference='content.image'
        objectFitKeyName='imageType'
        title='تصویر'
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        include={['padding']}
      />
      <PatternTitleItem
        reference='content.titleOne'
        title='عنوان'
        fontFamily
        collapsed={collapsed.titleOne}
        onCollapsedChange={handleCollapseChanged('titleOne')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternEditorDescriptionItem
        reference='content.description'
        title='توضیحات'
        fullwidth
        collapsed={collapsed.description}
        onCollapsedChange={handleCollapseChanged('description')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default StablePatternContentSection;
