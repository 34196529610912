// Core Utilities
import apiHandler from 'core/utilities/apiHandler';

// Feature Utilities
import { processEndpoints as endpoints } from 'features/form/processes/utilities/api/endpoints';
import { processItemEndpoints } from 'features/form/processes/utilities/api/endpoints';
import { transformApiProcess } from 'features/form/processes/utilities/transformers/process';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type { ProcessSummaryProps } from 'features/form/processes/types/list';
import type { ProcessItemSummaryProps } from 'features/form/processes/types/list/item';
import type {
  ProcessDataProps,
  ProcessProps,
} from 'features/form/processes/types/details';
import {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';

/**
 * Adds a new process.
 *
 * @param {ProcessDataProps} process - The process data to be added.
 * @returns {Promise<{ status: number; processId: string }>} The status of the operation and the ID of the created process.
 */
export const addProcess = async (
  process: ProcessDataProps
): Promise<{ status: number; processId: string }> => {
  const endpoint = endpoints.add;
  const body = {
    title: `فرایند ${process.formTitle}`,
    formId: process.formId,
  };
  const { status, data: createdProcess } = await apiHandler.post<{
    docs?: ProcessProps;
  }>(endpoint, body);
  return {
    status: status,
    processId: createdProcess?.docs?.id || '',
  };
};

/**
 * Edits an existing process.
 *
 * @param {string} processId - The ID of the process to be edited.
 * @param {ProcessDataProps} data - The new data for the process.
 * @returns {Promise<{ status: number; doc?: ProcessProps }>} The status of the operation and the updated process document.
 */
export const editProcess = async (
  processId: string,
  data: ProcessDataProps
): Promise<{ status: number; doc?: ProcessProps }> => {
  const endpoint = endpoints.edit(processId);
  const body = {
    ...data,
    title: `فرایند ${data.formTitle}`,
    formId: data.formId,
  };
  const { status, data: response } = await apiHandler.patch<{
    docs: ProcessProps;
  }>(endpoint, body);
  return {
    status: status,
    doc: response?.docs,
  };
};

/**
 * Toggles the active status of a process.
 *
 * @param {string} processId - The ID of the process to be toggled.
 * @param {boolean} value - The new active status of the process.
 * @returns {Promise<{ status: number; checked: boolean | undefined }>} The status of the operation and the new active status of the process.
 */
export const toggleProcessIsActive = async (
  processId: string,
  value: boolean
): Promise<{ status: number; checked: boolean | undefined }> => {
  const endpoint = endpoints.edit(processId);
  const { status, data: response } = await apiHandler.patch<{
    docs: ProcessProps;
  }>(endpoint, { isActive: value });
  return { status, checked: response?.docs?.data?.isActive };
};

/**
 * Retrieves a process by its ID.
 *
 * @param {string} processId - The ID of the process to retrieve.
 * @param {AbortSignal} [signal] - Optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<ProcessProps>>} The status of the operation and the retrieved process document.
 */
export const getProcess = async (
  processId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<ProcessProps>> => {
  const { status, data } = await apiHandler.get<{ docs: ProcessProps }>(
    endpoints.getById(processId),
    { signal }
  );
  const process = data?.docs ? transformApiProcess(data?.docs) : undefined;
  return { status, doc: process };
};

/**
 * Retrieves a list of processes.
 *
 * @param {AbortSignal} [signal] - Optional signal to abort the request.
 * @param {Record<string, any>} [query] - Optional query parameters for the request.
 * @returns {Promise<ApiGetDocsResponse<ProcessSummaryProps>>} The status of the operation, the list of processes, and pagination information.
 */
export const getProcesses = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<ProcessSummaryProps>> => {
  const endpoint = endpoints.getList(query);
  const { status, data } = await apiHandler.get<{
    docs: { data: ProcessSummaryProps[]; paginate: ApiPaginationProps };
  }>(endpoint, {
    signal,
  });
  const page = {
    current: data?.docs?.paginate?.page || 1,
    size: data?.docs?.paginate?.limit || 20,
    totalDocs: data?.docs?.paginate?.totalDocs || 0,
  };
  const list = data?.docs?.data || [];
  return { status, list, page };
};

/**
 * Removes a process by its ID.
 *
 * @param {string} id - The ID of the process to remove.
 * @returns {Promise<number>} The status of the operation.
 */
export const removeProcessById = async (id: string) => {
  const { status } = await apiHandler.delete(endpoints.removeById(id));
  return status;
};

/**
 * Retrieves items associated with a process.
 *
 * @param {string} processId - The ID of the process to retrieve items for.
 * @param {AbortSignal} [signal] - Optional signal to abort the request.
 * @returns {Promise<{ status: number; docs: ProcessItemSummaryProps[] }>} The status of the operation and the list of process items.
 */
export const getProcessItems = async (
  processId: string,
  signal?: AbortSignal
): Promise<{ status: number; docs: ProcessItemSummaryProps[] }> => {
  const endpoint = processItemEndpoints.getSummaryListByParams({
    processBlueprintId: processId,
  });
  const { status, data: response } = await apiHandler.get<{
    docs: ProcessItemSummaryProps[];
  }>(endpoint, { signal });
  return { status, docs: response?.docs || [] };
};

/**
 * Removes multiple processes by their IDs.
 *
 * @param {string[]} ids - The IDs of the processes to remove.
 * @returns {Promise<{ status: number }>} The status of the operation.
 */
export const removeProcesses = async (
  ids: string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(endpoints.remove, {
    ids,
  });
  return { status };
};
