import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { SxProps, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Custom Icons
import ChevronLeftIcon from 'core/components/icons/ChevronLeft';
import ChevronRightIcon from 'core/components/icons/ChevronRight';

// Custom Utilities
import { forLoop } from 'core/utilities/helper';
import { getFeedbackSummaryList } from 'features/form/feedbacks/utilities/api/details';
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FeedbackSummaryProps } from 'features/form/feedbacks/types';
import type { ApiGetSummaryHandler } from 'core/types/api/hook/handler';

interface UserAutocompleteProps {
  sx?: SxProps;
}

const UserAutocomplete: React.FC<UserAutocompleteProps> = (props) => {
  // Props
  const { sx } = props;

  // Context
  const loading = useSelectLoading();

  // States
  const [selectedOpt, setSelectedOpt] = useState<FeedbackSummaryProps | null>(
    null
  );
  const [list, setList] = useState<FeedbackSummaryProps[]>([]);
  const [isFirstOption, setIsFirstOption] = useState<boolean>(false);
  const [isLastOption, setIsLastOption] = useState<boolean>(false);

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const activeId = searchParams.get('activeId');
  const feedbackIds = searchParams.getAll('feedbackId');

  const apiHandler = useCallback(
    async (signal?: AbortSignal) => {
      const { status, list = [] } = await getFeedbackSummaryList(
        feedbackIds,
        signal
      );

      const listWithIndex =
        list.length > 0
          ? list.map((item, index) => ({
              id: item.id,
              data: { title: `${index + 1}.${item.data.title}`.toPersian() },
            }))
          : [];

      return { status, list: listWithIndex || [] };
    },
    [feedbackIds]
  );

  const mapIdToIndex = useMemo(() => {
    const idToIndex: Record<string, number> = {};
    forLoop(list, (item, index) => (idToIndex[item.id] = index));
    return idToIndex;
  }, [list]);

  useEffect(() => {
    if (!activeId || list.length === 0) return;

    const index = mapIdToIndex[activeId];

    setSelectedOpt(list[index]);
    setIsFirstOption(index === 0);
    setIsLastOption(list.length === index + 1);
  }, [list, activeId]);

  useEffect(() => {
    if (!selectedOpt || selectedOpt?.id === activeId) return;

    searchParams.set('activeId', selectedOpt.id);
    setSearchParams(searchParams, { replace: true });
  }, [selectedOpt]);

  // Utilities
  const { palette } = useTheme();

  const handleNext = useCallback(() => {
    if (!selectedOpt) return;
    setSelectedOpt(list[mapIdToIndex[selectedOpt.id] + 1]);
  }, [selectedOpt, list, mapIdToIndex]);

  const handlePrev = useCallback(() => {
    if (!selectedOpt) return;
    setSelectedOpt(list[mapIdToIndex[selectedOpt.id] - 1]);
  }, [selectedOpt, list, mapIdToIndex]);

  // Render
  return (
    <RowStack
      spacing='.5rem'
      alignItems='center'
      className='hide-on-print'
      sx={sx}
    >
      <Button
        sx={{
          minWidth: '2.5rem',
          maxWidth: '2.5rem',
          minHeight: '2.5rem',
          maxHeight: '2.5rem',
          borderRadius: '.5rem',
          background: palette.background.paper,
          borderColor: palette.divider,
          color: palette.text.disabled,
          border: '1px solid #C1C8D3',
          ':hover': {
            background: palette.background.paper,
            opacity: '.7',
          },
        }}
        variant='contained'
        onClick={handlePrev}
        disabled={isFirstOption || !selectedOpt || loading}
      >
        <ChevronRightIcon />
      </Button>
      <SingleAutocomplete
        disableClearable
        disabled={loading}
        onChange={(id, opt) => setSelectedOpt(opt)}
        sx={{
          '* > .MuiAutocomplete-input': {
            maxWidth: '8.25rem',
          },
          '.MuiInputBase-root': {
            padding: '0.313rem !important',
            width: '11.25rem',
          },
        }}
        value={selectedOpt}
        apiHandler={apiHandler}
        queryKey={['feedbacks']}
        onOptionsChange={(options) =>
          setList(options as FeedbackSummaryProps[])
        }
      />
      <Button
        variant='contained'
        onClick={handleNext}
        disabled={isLastOption || !selectedOpt || loading}
        sx={{
          minWidth: '2.5rem',
          maxWidth: '2.5rem',
          minHeight: '2.5rem',
          maxHeight: '2.5rem',
          border: '1px solid #C1C8D3',
          borderRadius: '.5rem',
          color: palette.text.disabled,
          background: palette.background.paper,
          ':hover': {
            background: palette.background.paper,
            opacity: '.7',
          },
        }}
      >
        <ChevronLeftIcon />
      </Button>
    </RowStack>
  );
};

export default memo(UserAutocomplete, isSame);
