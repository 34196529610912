import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Feature Shared Components
import GalleryUploadButton from 'features/file/files/components/shared/Gallery/UploadButton';
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternImagePositionDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/ImagePosition';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';
import PatternBgTypeDropdown from 'features/appBuilder/patterns/core/components/shared/patternBackground/PatternBgTypeDropdown';
import PatternCoverImagePositionDropdown from 'features/appBuilder/patterns/core/components/shared/CoverImagePosition';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';
export interface PatternImageItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  noUpload?: boolean;
  playIcon?: boolean;
  objectFitKeyName?: string;
  useHeightAndWidth?: boolean;
  position?: boolean;
}

const PatternImageItem: FC<PatternImageItemProps> = (props) => {
  // Props
  const {
    objectFitKeyName = 'objectFit',
    useHeightAndWidth,
    reference,
    noUpload,
    playIcon,
    position,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept: ['image'],
      location: 'pageBuilder',
      onInsertFile: (files) => setValue(`${reference}.file`, files[0]),
    });
  };

  // Helpers
  const ImageType = (
    <InputWrapper label='تناسب عکس' sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={`${reference}.${objectFitKeyName}`}
        render={({ field }) => (
          <PatternBgTypeDropdown
            disabled={loading}
            keyName={field.name}
            defaultValue={field.value}
            disableTile
            sx={{ height: '3rem', width: noUpload ? '100%' : undefined }}
          />
        )}
      />
    </InputWrapper>
  );

  const ImagePosition = (
    <InputWrapper label='محل تصویر' sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={`${reference}.position`}
        render={({ field }) => (
          <PatternCoverImagePositionDropdown
            disabled={loading}
            keyName={field.name}
            defaultValue={field.value}
            disableTile
            sx={{ height: '3rem', width: noUpload ? '100%' : undefined }}
          />
        )}
      />
    </InputWrapper>
  );

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      {!noUpload && (
        <>
          <InputWrapper label='تصویر'>
            <Controller
              control={control}
              name={`${reference}.file`}
              render={({ field }) => (
                <GalleryUploadButton
                  file={field.value ?? undefined}
                  disabled={loading}
                  onClick={handleOpenDialog}
                  onRemove={() => setValue(field.name, null)}
                  sx={{ width: '10rem', height: '2.25rem' }}
                />
              )}
            />
          </InputWrapper>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              '[&>*]': { flexGrow: 1 },
            }}
          >
            <InputWrapper label='محل قرارگیری' sx={{ width: '100%' }}>
              <Controller
                control={control}
                name={`${reference}.imagePosition`}
                render={({ field }) => (
                  <PatternImagePositionDropdown
                    disabled={loading}
                    fullWidth
                    defaultValue={field.value}
                    reference={reference}
                  />
                )}
              />
            </InputWrapper>
            {ImageType}
          </Box>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          '[&>*]': { flexGrow: 1 },
        }}
      >
        <InputWrapper label='نوع نمایش' sx={{ width: '100%' }}>
          <Controller
            control={control}
            name={`${reference}.displayType`}
            render={({ field }) => (
              <PatternImageDisplayTypeDropdown
                disabled={loading}
                fullWidth
                defaultValue={field.value}
                reference={reference}
              />
            )}
          />
        </InputWrapper>
        {useHeightAndWidth ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <InputWrapper label={'ارتفاع'} sx={{ width: '100%' }}>
              <Controller
                control={control}
                name={`${reference}.displayType`}
                render={({ field: f }) => (
                  <Controller
                    control={control}
                    name={`${reference}.height`}
                    render={({ field }) => (
                      <TextFieldNumber
                        disabled={f.value === 'default' || loading}
                        {...field}
                      />
                    )}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper label={'عرض'} sx={{ width: '100%' }}>
              <Controller
                control={control}
                name={`${reference}.displayType`}
                render={({ field: f }) => (
                  <Controller
                    control={control}
                    name={`${reference}.width`}
                    render={({ field }) => (
                      <TextFieldNumber
                        disabled={f.value === 'default' || loading}
                        {...field}
                      />
                    )}
                  />
                )}
              />
            </InputWrapper>
          </Box>
        ) : (
          <InputWrapper
            label={noUpload ? 'ارتفاع' : 'اندازه'}
            sx={{ width: '100%' }}
          >
            <Controller
              control={control}
              name={`${reference}.displayType`}
              render={({ field: f }) => (
                <Controller
                  control={control}
                  name={`${reference}.imageSize`}
                  render={({ field }) => (
                    <TextFieldNumber
                      disabled={f.value === 'default' || loading}
                      {...field}
                    />
                  )}
                />
              )}
            />
          </InputWrapper>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {noUpload && ImageType}
        {position && ImagePosition}
      </Box>
      {playIcon && (
        <Controller
          control={control}
          name={`${reference}.showPlayIcon`}
          render={({ field }) => (
            <Box sx={{ display: 'flex', gap: 0.25, alignItems: 'center' }}>
              <Checkbox checked={field.value} disabled={loading} {...field} />
              <BodyTwo>نمایش آیکن پخش ویدئو</BodyTwo>
            </Box>
          )}
        />
      )}
    </PatternItemContainer>
  );
};

export default PatternImageItem;
