// Feature Types
import { PatternTabItemProps } from 'features/appBuilder/patterns/core/types/item/tab';
import { SliderPatternBodyProps } from 'features/appBuilder/patterns/features/slider/types/body';

export const sliderPatternTabDefaults: PatternTabItemProps = {
  isActive: true,
  refType: '',
  archiveLink: '',
  archiveTitle: '',
  padding: '0px',
  sourceId: '',
  sourceTitle: '',
  title: '',
};

export const sliderPatternBodyDefaults: SliderPatternBodyProps = {
  isActive: true,
  tabs: [sliderPatternTabDefaults],
};
