// Feature Types
import { FooterPatternBannerProps } from 'features/appBuilder/patterns/features/footer/types/banner';

export const footerPatternBannerDefaults: FooterPatternBannerProps = {
  isActive: true,
  one: {
    containerId: '',
    containerTitle: '',
    imageSize: 32,
    imageType: 'default',
    isActive: true,
    itemCount: 6,
    padding: '0px',
    textAlign: 'end',
  },
};
