import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';
import PatternDateItem from 'features/appBuilder/patterns/core/components/shared/items/Date';
import PatternShareButtonItem from 'features/appBuilder/patterns/core/components/shared/items/ShareButton';

// Custom Types
export interface ContentPatternFooterSectionItemProps {}
type CollapsedKey = 'section' | 'date' | 'button' | 'tag' | 'category';

const ContentDetailsPatternFooterSection: FC<
  ContentPatternFooterSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    date: false,
    button: false,
    tag: false,
    category: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='زیرنویس'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='footer'
    >
      <PatternDateItem
        reference='footer.date'
        title='تاریخ'
        collapsed={collapsed.date}
        onCollapsedChange={handleCollapseChanged('date')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
        ]}
      />
      <PatternShareButtonItem
        reference='footer.button'
        title='دکمه‌ها'
        collapsed={collapsed.button}
        onCollapsedChange={handleCollapseChanged('button')}
        include={[
          'padding',
          'textAlign',
          'fontColor',
          'bgColor',
          'fontHoverColor',
          'bgHoverColor',
        ]}
      />
      <PatternEmptyItem
        reference='footer.tag'
        title='برچسب'
        collapsed={collapsed.tag}
        onCollapsedChange={handleCollapseChanged('tag')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'bgColor',
          'fontHoverColor',
          'bgHoverColor',
        ]}
      />
      <PatternEmptyItem
        reference='footer.category'
        title='موضوع'
        collapsed={collapsed.category}
        onCollapsedChange={handleCollapseChanged('category')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'bgColor',
          'fontHoverColor',
          'bgHoverColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default ContentDetailsPatternFooterSection;
