import { useEffect, useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternRefTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/RefType';
import PatternSourceDropdown, {
  SourceDropdownSelectOption,
} from 'features/appBuilder/patterns/core/components/shared/dropdown/Source';
import { PatternRefType } from 'features/appBuilder/patterns/core/types/item/common';
import { getBannerSummaryList } from 'features/banner/banners/utilities/api';
import { getCategoriesSummaryList } from 'features/content/categories/utilities/api';
import { getTagsSummaryList } from 'features/content/tags/utilities/api';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { isSucceed } from 'core/utilities/helper';

// Custom Types
export interface PatternTabItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  hideTitle?: boolean;
  hideArchive?: boolean;
}

const PatternTabItem: FC<PatternTabItemProps> = (props) => {
  // Props
  const { reference, hideTitle, hideArchive, ...otherProps } = props;

  // Hooks
  const [options, setOptions] = useState<SourceDropdownSelectOption[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const { control, getValues } = useFormContext();
  const loading = useSelectLoading();

  useEffect(() => {
    initializeOptions();
  }, []);

  // Utilities
  const handleRefTypeChange = async (refType: PatternRefType) => {
    setFetching(true);

    if (refType === 'banner') {
      const { status, list } = await getBannerSummaryList();

      if (status && isSucceed(status) && list && list.length > 0) {
        setOptions(list);
      }
    }

    if (refType === 'category') {
      const { status, list } = await getCategoriesSummaryList();

      if (status && isSucceed(status) && list && list.length > 0) {
        setOptions(list);
      }
    }

    if (refType === 'tag') {
      const { status, list } = await getTagsSummaryList();

      if (status && isSucceed(status) && list && list.length > 0) {
        setOptions(list);
      }
    }

    setFetching(false);
  };

  const initializeOptions = () => {
    const refType = getValues(`${reference}.refType`);

    if (options.length === 0) {
      handleRefTypeChange(refType);
    }
  };

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      {!hideTitle && (
        <InputWrapper disabled={loading} required label='عنوان تب'>
          <Controller
            control={control}
            name={`${reference}.title`}
            render={({ field }) => <TextField disabled={loading} {...field} />}
          />
        </InputWrapper>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          ['&>*']: { flexGrow: 1, width: '100%' },
        }}
      >
        <InputWrapper disabled={loading} required label='نوع مرجع'>
          <Controller
            control={control}
            name={`${reference}.refType`}
            render={({ field }) => (
              <PatternRefTypeDropdown
                disabled={loading}
                defaultValue={field.value}
                reference={reference}
                onRefTypeChange={(type) => handleRefTypeChange(type)}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper disabled={loading} required label='منبع'>
          <Controller
            control={control}
            name={`${reference}.sourceId`}
            render={({ field }) => (
              <PatternSourceDropdown
                disabled={loading}
                loading={fetching}
                opts={options}
                defaultValue={field.value}
                reference={reference}
              />
            )}
          />
        </InputWrapper>
      </Box>
      {!hideArchive && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            ['&>*']: { flexGrow: 1, width: '100%' },
          }}
        >
          <InputWrapper disabled={loading} label='عنوان بایگانی'>
            <Controller
              control={control}
              name={`${reference}.archiveTitle`}
              render={({ field }) => (
                <TextField disabled={loading} {...field} />
              )}
            />
          </InputWrapper>
          <InputWrapper disabled={loading} label='آدرس بایگانی'>
            <Controller
              control={control}
              name={`${reference}.archiveLink`}
              render={({ field }) => (
                <TextField disabled={loading} dir='ltr' {...field} />
              )}
            />
          </InputWrapper>
        </Box>
      )}
    </PatternItemContainer>
  );
};

export default PatternTabItem;
