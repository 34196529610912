// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { formFeedbacksListEndpoints } from '../endpoints';

// Custom Types
import type { DeprecatedApiGetDocsHandler } from 'core/types/api/hook/handler';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  FeedbackOverviewProps,
  FeedbackProps,
  FeedbackSummaryProps,
} from 'features/form/feedbacks/types';

/**
 * Fetches feedback details by its ID from the API.
 *
 * @param {string} id - The ID of the feedback to retrieve.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @returns {Promise<ApiGetDocResponse<FeedbackOverviewProps>>} A promise that resolves to an object containing the status and the feedback details.
 */
export const getFeedback = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<FeedbackOverviewProps>> => {
  const { status, data } = await apiHandler.get<{
    docs: FeedbackOverviewProps;
  }>(formFeedbacksListEndpoints.getById(id), {
    signal,
  });

  return { status, doc: data?.docs };
};

/**
 * Fetches a summary list of feedback based on the provided IDs.
 *
 * @param {string[]} ids - An array of feedback IDs for which to fetch the summaries.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetSummaryResponse<FeedbackSummaryProps>>}
 *          A promise that resolves to an object containing the HTTP status and a list of feedback summaries.
 */
export const getFeedbackSummaryList = async (
  ids: string[],
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<FeedbackSummaryProps>> => {
  const { status, data } = await apiHandler.get<{
    docs: FeedbackSummaryProps[];
  }>(formFeedbacksListEndpoints.getListSummary(ids), {
    signal,
  });

  return { status, list: data?.docs || [] };
};

/**
 * Retrieves detailed feedback values based on the provided configurations.
 *
 * @param {Object} [configs] - Optional configurations for retrieving feedback values.
 * @param {string[]} [configs.id] - An array of feedback IDs to retrieve.
 * @param {Object} [configs.additionalData] - Additional data for the request.
 * @param {string} [configs.additionalData.formId] - The form ID associated with the feedback.
 * @param {Object} [configs.page] - Pagination information.
 * @param {Object} [configs.page.current] - The current page number.
 * @param {Object} [configs.page.size] - The number of items per page.
 * @param {string} [configs.params] - Additional query parameters for the request.
 * @param {AxiosCancelToken} [configs.cancelToken] - Optional Axios cancel token to cancel the request.
 * @returns {Promise<{ status: number; list: FeedbackProps[]; page: { current: number; size: number; totalDocs: number } }>}
 *          A promise that resolves to an object containing the status, list of feedback values, and pagination info.
 */
export const getFeedbackValues: DeprecatedApiGetDocsHandler<
  FeedbackProps
> = async (configs?) => {
  const ids = (configs?.id || []) as string[];
  const formId = configs?.additionalData?.formId || '';
  const endpoint = `${formFeedbacksListEndpoints.getDocsDetails(
    formId,
    ids
  )}&page=${configs?.page?.current}&limit=${configs?.page?.size}${
    configs?.params ? `&${configs?.params}` : ''
  }`;

  const { status, data } = await apiHandler.get<{
    docs: {
      data: FeedbackProps[];
      paginate: ApiPaginationProps;
    };
  }>(endpoint);

  const page = {
    current: data?.docs?.paginate?.page || 1,
    size: data?.docs?.paginate?.limit || 20,
    totalDocs: data?.docs?.paginate?.totalDocs || 0,
  };
  const list = data?.docs?.data || [];
  return { status, list, page };
};
