// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';

// Types;
import type { TicketTopicsProps } from 'features/ticket/topics/types';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type {
  TicketTopicDataProps,
  TicketTopicProps,
} from 'features/ticket/topics/types';

/**
 * Fetches a list of ticket topics.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [query] - Optional query parameters for the request.
 * @returns {Promise<ApiGetDocsResponse<TicketTopicsProps>>} The status of the request, the list of ticket topics, and pagination information.
 */
export const getTicketTopics = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<TicketTopicsProps>> => {
  const { getTicketTopicsURL } = getEndpoint();
  const { status, data } = await apiHandler.get<{
    docs: { data: TicketTopicsProps[]; paginate: ApiPaginationProps };
  }>(getUrlWithQueryString(getTicketTopicsURL, query), {
    signal,
  });
  const page = {
    current: data?.docs?.paginate?.page || 1,
    size: data?.docs?.paginate?.limit || 20,
    totalDocs: data?.docs?.paginate?.totalDocs || 0,
  };
  return { status, list: data?.docs?.data || [], page };
};

/**
 * Fetches a single ticket topic by its ID.
 *
 * @param {string} id - The ID of the ticket topic to fetch.
 * @returns {Promise<ApiGetDocResponse<TicketTopicProps>>} The status of the request and the ticket topic document.
 */
export const getTicketTopic = async (
  id: string
): Promise<ApiGetDocResponse<TicketTopicProps>> => {
  const { getTicketTopicURL } = getEndpoint();
  let endpoint = `${getTicketTopicURL}/${id}`;
  const { status, data } = await apiHandler.get<{
    docs: TicketTopicProps;
  }>(endpoint);
  return { status, doc: data?.docs };
};

/**
 * Toggles the status of a ticket topic.
 *
 * @param {string} topicId - The ID of the ticket topic to update.
 * @param {boolean} statusValue - The new status value to set (active/inactive).
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const toggleTicketTopicStatus = async (
  topicId: string,
  statusValue: boolean
): Promise<{ status: number }> => {
  const { toggleTicketTopicStatusURL } = getEndpoint();
  const endpoint = `${toggleTicketTopicStatusURL}/${topicId}`;
  const { status } = await apiHandler.put(endpoint, { isActive: statusValue });
  return { status };
};

/**
 * Deletes specified ticket topics.
 *
 * @param {string[]} topicIds - An array of ticket topic IDs to delete.
 * @returns {Promise<{ status: number }>} The status of the delete request.
 */
export const deleteTicketTopics = async (
  topicIds: string[]
): Promise<{ status: number }> => {
  const { deleteTicketTopicURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.delete(endpoint, { topicIds });
  return { status };
};

/**
 * Adds a new ticket topic.
 *
 * @param {TicketTopicDataProps} data - The data for the new ticket topic.
 * @returns {Promise<{ status: number }>} The status of the add request.
 */
export const addTicketTopic = async (
  data: TicketTopicDataProps
): Promise<{ status: number }> => {
  const { addTicketTopicsURL: endpoint } = getEndpoint();
  const body = {
    title: data.title,
    personnel: data.personnel,
    department: data.department,
    isActive: data.isActive,
    expireAfter: data.expireAfter,
    relatedMediaId: data.relatedMediaId,
    subscribers: {
      audienceIds: data.subscribers.audienceIds,
      openAndClose: data.subscribers.availableOpts.openAndClose,
      mention: data.subscribers.availableOpts.mention,
      replyToCreator: data.subscribers.availableOpts.replyToCreator,
      replyFromCreator: data.subscribers.availableOpts.replyFromCreator,
    },
  };
  const { status } = await apiHandler.post(endpoint, body);
  return { status };
};

/**
 * Edits an existing ticket topic.
 *
 * @param {string} id - The ID of the ticket topic to edit.
 * @param {TicketTopicDataProps} data - The updated data for the ticket topic.
 * @returns {Promise<{ status: number }>} The status of the edit request.
 */
export const editTicketTopic = async (
  id: string,
  data: TicketTopicDataProps
): Promise<{ status: number }> => {
  const { editTicketTopicsURL } = getEndpoint();
  const endpoint = `${editTicketTopicsURL}/${id}`;
  const body = {
    title: data.title,
    personnel: data.personnel,
    department: data.department,
    isActive: data.isActive,
    expireAfter: data.expireAfter,
    relatedMediaId: data.relatedMediaId,
    subscribers: {
      audienceIds: data.subscribers.audienceIds,
      openAndClose: data.subscribers.availableOpts.openAndClose,
      mention: data.subscribers.availableOpts.mention,
      replyToCreator: data.subscribers.availableOpts.replyToCreator,
      replyFromCreator: data.subscribers.availableOpts.replyFromCreator,
    },
  };
  const { status } = await apiHandler.put(endpoint, body);
  return { status };
};
