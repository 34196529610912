// Feature Types
import { HeaderPatternBrandProps } from 'features/appBuilder/patterns/features/header/types/brand';

export const headerPatternBrandDefaults: HeaderPatternBrandProps = {
  isActive: true,
  logo: {
    isActive: true,
    displayType: 'default',
    imageSize: 0,
    file: null,
    padding: '0px',
  },
  titleOne: {
    isActive: true,
    fontColor: '#12252E',
    fontSize: '18',
    highlightColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
    text: '',
    textAlign: 'start',
    textDirection: 'rtl',
    fontFamily: 'vazir',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
  titleTwo: {
    isActive: true,
    fontColor: '#59666D',
    fontSize: '14',
    highlightColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
    text: '',
    textAlign: 'start',
    textDirection: 'rtl',
    fontFamily: 'vazir',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#59666D',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
  searchbar: {
    isActive: true,
    color: '#59666D',
    bgColor: '#C1C8D3',
    borderColor: 'rgba(0, 0, 0, 0)',
    fontSize: '12',
    padding: '0px',
    skinId: '',
    skinTitle: '',
    textDirection: 'rtl',
    title: 'جستجو کنید',
  },
};
