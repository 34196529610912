// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { bakePattern } from 'features/appBuilder/patterns/utilities';
import { transformPatternContainers } from './transformer';

// Custom Types
import type { PatternDataType } from 'features/appBuilder/patterns/types/type';
import type { PatternType } from 'features/appBuilder/patterns//core/types/item';
import type { PatternItemProps } from 'features/appBuilder/patterns/types/item';
import type { PatternContainerProps } from 'features/appBuilder/patterns/types/container';
import type { TablePatternProps } from 'features/appBuilder/patterns/types';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';

export const getPatternsOf = async (
  type: PatternType,
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<PatternItemProps>> => {
  const { getPatterns } = getEndpoint();
  const endpoint = `${getPatterns}/${type}`;

  const { status, data: response } = await apiHandler.get<{
    docs: PatternItemProps[];
  }>(endpoint, {
    signal,
  });

  return { status, list: response?.docs };
};

export const getPatterns = async (): Promise<{
  list: PatternContainerProps[];
  status: number;
}> => {
  const endpoint = getEndpoint().getPatternCountURL;

  const { status, data: response } = await apiHandler.get<{
    docs: { data: { type: PatternType; itemsCount: number }[] };
  }>(endpoint);

  return {
    status,
    list: response?.docs.data
      ? transformPatternContainers(response?.docs.data)
      : [],
  };
};

export const duplicatePattern = async (
  type: PatternType,
  id: string
): Promise<{ status: number }> => {
  const { duplicatePattern: endpoint } = getEndpoint();

  const { status } = await apiHandler.post(`${endpoint}/${type}?id=${id}`);
  return { status };
};

export const getPattern = async (
  type: PatternType,
  patternId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<PatternType>> => {
  const { getPattern } = getEndpoint();

  const endpoint = `${getPattern}/${type}?id=${patternId}`;

  const { status, data: response } = await apiHandler.get<{
    docs: PatternType;
  }>(endpoint, {
    signal,
  });

  return { status, doc: response?.docs };
};

export const addPattern = async (
  payload: { type: PatternType; pattern: PatternDataType },
  signal?: AbortSignal
): Promise<{ status: number }> => {
  const { addPattern } = getEndpoint();
  const endpoint = `${addPattern}/${payload.type}`;

  const { status } = await apiHandler.post(
    endpoint,
    bakePattern(payload.pattern),
    {
      signal,
    }
  );

  return { status };
};

export const editPattern = async (
  type: PatternType,
  patternId: string,
  pattern: PatternDataType
): Promise<{ status: number }> => {
  const { editPattern } = getEndpoint();
  const endpoint = `${editPattern}/${type}?id=${patternId}`;

  const { status } = await apiHandler.patch(endpoint, pattern);

  return { status };
};

export const deletePatterns = async (
  payload: { type: PatternType; ids: string[] },
  signal?: AbortSignal
): Promise<{ status: number }> => {
  const endPoint = getEndpoint();

  const { status } = await apiHandler.delete(
    `${endPoint.deletePattern}/${payload.type}`,
    { ids: payload.ids },
    { signal }
  );

  return { status };
};

export const getPatternPreview = async (
  patternId: string,
  audienceId: string
): Promise<{ status: number; previewData?: TablePatternProps }> => {
  const endPoint = `${
    getEndpoint().getPreview
  }/${patternId}?audienceId=${audienceId}`;

  const { status, data: response } = await apiHandler.get<{
    docs: TablePatternProps;
  }>(endPoint);

  return { status, previewData: response?.docs };
};
