// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { bakeAudienceFolder } from 'features/audience/folders/utilities/api/baker';
import { transformAudienceFolder } from 'features/audience/folders/utilities/transformer';

// Custom Types
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type { ApiAudienceFoldersProps } from 'features/audience/folders/types/api';
import type {
  AudienceFolderDataProps,
  AudienceFolderProps,
} from 'features/audience/folders/types';

export const getAudienceFolders = async (
  signal: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<AudienceFolderProps>> => {
  const { getAudienceFoldersURl } = getEndpoint();
  const { status, data } = await apiHandler.get<{
    docs: ApiAudienceFoldersProps[];
  }>(getUrlWithQueryString(getAudienceFoldersURl, query), {
    signal,
  });

  const audienceFolders: AudienceFolderProps[] = data?.docs
    ? data.docs.map(transformAudienceFolder)
    : [];

  return { status, list: audienceFolders };
};

/**
 * Fetches a single audience folder by its ID from the API.
 *
 * @param {string} folderId - The ID of the audience folder to fetch.
 * @returns {Promise<ApiGetDocResponse<AudienceFolderProps>>}
 * An object containing the HTTP status and the fetched audience folder.
 */
export const getAudienceFolder = async (
  folderId: string
): Promise<ApiGetDocResponse<AudienceFolderProps>> => {
  const { getAudienceFolderURL } = getEndpoint();
  const endpoint = `${getAudienceFolderURL}/${folderId}`;

  const { status, data: response } = await apiHandler.get<{
    docs: ApiAudienceFoldersProps;
  }>(endpoint);

  return {
    status,
    doc: response?.docs ? transformAudienceFolder(response?.docs) : undefined,
  };
};

export const addAudienceFolder = async (
  folder: AudienceFolderDataProps
): Promise<{ status: number }> => {
  const { addAudienceFolderURL: endpoint } = getEndpoint();
  const baked = bakeAudienceFolder(folder);

  const { status } = await apiHandler.post(endpoint, baked);

  return { status };
};

/**
 * Edits an existing audience folder in the API.
 *
 * @param {string} folderId - The ID of the audience folder to edit.
 * @param {AudienceFolderDataProps} folder - The updated audience folder data.
 * @returns {Promise<{ status: number; audienceFolder?: AudienceFolderProps }>}
 * An object containing the HTTP status and the updated audience folder.
 */
export const editAudienceFolder = async (
  folderId: string,
  folder: AudienceFolderDataProps
): Promise<{ status: number; audienceFolder?: AudienceFolderProps }> => {
  const { editAudienceFolderURL } = getEndpoint();
  const endpoint = `${editAudienceFolderURL}/${folderId}`;
  const baked = bakeAudienceFolder(folder);
  const { status, data } = await apiHandler.patch(endpoint, baked);
  const { docs } = data as { docs: ApiAudienceFoldersProps };
  const audienceFolder: AudienceFolderProps = transformAudienceFolder(docs);
  return { status, audienceFolder };
};

export const deleteAudienceFolders = async (
  foldersIds: string[]
): Promise<{ status: number }> => {
  const { deleteAudienceFoldersByIdURL: endpoint } = getEndpoint();

  const { status } = await apiHandler.delete(endpoint, { ids: foldersIds });

  return { status };
};
