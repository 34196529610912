// Feature Types
import type { TagAndCategoryPatternCardConfigProps } from 'features/appBuilder/patterns/features/tagAndCategory/types/cardConfig';

export const tagAndCategoryPatterCardConfigDefaults: TagAndCategoryPatternCardConfigProps =
  {
    isActive: true,
    title: {
      isActive: true,
      lineClamp: 1,
      padding: '0px',
      fontSize: '18',
      textAlign: 'start',
      textDirection: 'rtl',
      fontColor: '#12252E',
      highlightColor: 'rgba(0, 0, 0, 0)',
      fontHoverColor: '#12252E',
      highlightHoverColor: 'rgba(0, 0, 0, 0)',
    },
    body: {
      isActive: true,
      lineClamp: 4,
      padding: '0px',
      fontSize: '12',
      textDirection: 'rtl',
      textAlign: 'start',
      fontColor: '#59666D',
      highlightColor: 'rgba(0, 0, 0, 0)',
      fontHoverColor: '#59666D',
      highlightHoverColor: 'rgba(0, 0, 0, 0)',
    },
    image: {
      isActive: true,
      padding: '0px',
      displayType: 'default',
      imageSize: 224,
      objectFit: 'cover',
      height: 224,
      width: 224,
      position: 'afterTitle',
    },
    display: {
      isActive: true,
      align: 'start',
      padding: '0px',
      cardBg: '#FFFFFF',
      cardBgHover: '#FFFFFF',
    },
  };
