// Feature Types
import type { ContentDetailsPatternConfigProps } from 'features/appBuilder/patterns/features/contentDetails/types/config';

export const contentDetailsPatterConfigDefaults: ContentDetailsPatternConfigProps =
  {
    isActive: true,
    title: {
      isActive: true,
      padding: '0px',
      fontSize: '24',
      textDirection: 'rtl',
      textAlign: 'start',
      lineClamp: 1,
      fontColor: '#12252E',
      highlightColor: 'rgba(0, 0, 0, 0)',
      fontHoverColor: '#12252E',
      highlightHoverColor: 'rgba(0, 0, 0, 0)',
    },
    image: {
      isActive: true,
      displayType: 'default',
      imageSize: 328,
      height: 328,
      width: 596,
      objectFit: 'cover',
      padding: '0px',
      position: 'afterTitle',
    },
    abstract: {
      isActive: true,
      padding: '0px',
      fontSize: '16',
      textDirection: 'rtl',
      textAlign: 'start',
      lineClamp: 4,
      fontColor: '#59666D',
      highlightColor: 'rgba(0, 0, 0, 0)',
      fontHoverColor: '#59666D',
      highlightHoverColor: 'rgba(0, 0, 0, 0)',
    },
  };
