// Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { generateObjectId } from 'core/utilities/helper/id';
import { initialFile } from 'features/file/files/utilities/file';

// Types
import type { FileProps } from 'features/file/files/types';
import type { ApiGetDocResponse } from 'core/types/api/hook/response';
import type { ApiSettingsProps } from 'features/manager/settings/types/api';
import type {
  SettingsDataToSubmit,
  SettingsProps,
} from 'features/manager/settings/types';

/**
 * Retrieves settings data with optional cancellation support.
 *
 * @returns {Promise<ApiGetDocResponse<SettingsProps>>} A promise that resolves to an object
 * containing the status of the request and, if successful, the settings properties.
 */
export const getSettings = async (
  _?: any,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<SettingsProps>> => {
  const { getSettingsURL: endpoint } = getEndpoint();

  const { data, status } = await apiHandler.get<{
    docs?: ApiSettingsProps;
  }>(endpoint, { signal });

  const settings: SettingsProps | undefined = data?.docs
    ? {
        id: generateObjectId(),
        data: {
          relatedMediaId: data.docs?.releatedMediaId || '',
          enableDeleteFeedback: data?.docs?.enableDeleteFeedback,
          enableDeleteForm: data?.docs?.enableDeleteForm,
          description: data?.docs?.description,
          email: {
            address: data?.docs?.email.mail,
            name: data?.docs?.email.name,
            smtp: data?.docs?.email.smtp,
            username: data?.docs?.email.username,
          },
          fontFamily: data?.docs?.fontFamily || 'Vazir',
          favicon:
            data?.docs?.favicon && Object.keys(data?.docs?.favicon).length > 0
              ? (data?.docs?.favicon as FileProps)
              : initialFile,
          logo:
            data?.docs?.logo && Object.keys(data?.docs?.logo).length
              ? (data?.docs?.logo as FileProps)
              : initialFile,
          themes: data?.docs?.themes,
        },
      }
    : undefined;

  return { status, doc: settings };
};

/**
 * Submits updated settings data with optional cancellation support.
 *
 * @param {SettingsDataToSubmit} values - The updated settings data to be submitted.
 * @returns {Promise<{status:number}>} A promise that resolves when the settings have been successfully updated.
 */
export const editSettings = async (
  values: SettingsDataToSubmit
): Promise<{ status: number }> => {
  const { editSettingsURL: endpoint } = getEndpoint();

  const { email, ...otherFields } = values;

  const { status } = await apiHandler.post(endpoint, {
    ...email,
    ...otherFields,
  });

  return { status };
};
