// Feature Types
import { HeaderPatternButtonProps } from 'features/appBuilder/patterns/features/header/types/button';

export const headerPatternButtonDefaults: HeaderPatternButtonProps = {
  isActive: true,
  one: {
    padding: '8px 16px',
    fontSize: '14',
    textDirection: 'rtl',
    color: '#fff',
    bgColor: '#12252E',
    icon: null,
    iconPosition: 'start',
    isActive: true,
    link: '',
    title: '',
    variant: 'contained',
    hoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
  },
  two: {
    padding: '8px 16px',
    fontSize: '14',
    textDirection: 'rtl',
    color: '#fff',
    bgColor: '#12252E',
    icon: null,
    iconPosition: 'start',
    isActive: true,
    link: '',
    title: '',
    variant: 'outlined',
    hoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
  },
  logout: {
    padding: '8px 16px',
    fontSize: '14',
    textDirection: 'rtl',
    color: '#D32F2F',
    bgColor: 'rgba(0, 0, 0, 0)',
    icon: null,
    iconPosition: 'start',
    isActive: true,
    link: '',
    title: '',
    variant: 'outlined',
    hoverColor: '#D32F2F',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
};
