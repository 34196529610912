// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { bakeCustomPageBody } from 'features/appBuilder/customPages/utilities/api/baker';
import { transformCustomPage } from 'features/appBuilder/customPages/utilities/api/transformer';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type { ApiCustomPageProps } from 'features/appBuilder/customPages/types/api';
import type { CustomPageBodyDataProps } from 'features/appBuilder/customPages/types/body';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type {
  CustomPageDataProps,
  CustomPageProps,
  CustomPageSummaryProps,
} from 'features/appBuilder/customPages/types';

/**
 * Fetches custom pages with optional query parameters.
 *
 * @param {AbortSignal} [signal] - Optional signal to abort the request.
 * @param {Record<string, any>} [query] - Optional query parameters for the request.
 * @returns {Promise<ApiGetDocsResponse<CustomPageSummaryProps>>}
 * The status of the request and the fetched custom pages.
 */
export const getCustomPages = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<ApiGetDocsResponse<CustomPageSummaryProps>> => {
  const { getCustomPagesURL } = getEndpoint();
  const { data: response, status } = await apiHandler.get<{
    docs: {
      customPages: CustomPageSummaryProps[];
      paginate: ApiPaginationProps;
    };
  }>(getUrlWithQueryString(getCustomPagesURL, query), {
    signal,
  });
  const page = {
    current: response?.docs?.paginate.page || 1,
    size: response?.docs?.paginate.limit || 20,
    totalDocs: response?.docs?.paginate.totalDocs || 0,
  };
  const list = response?.docs?.customPages;
  return { status, list, page };
};

/**
 * Fetches a specific custom page by its ID.
 *
 * @param {string} pageId - The ID of the custom page to fetch.
 * @param {AbortSignal} [signal] - Optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<CustomPageProps>>}
 * The status of the request and the fetched custom page.
 */
export const getCustomPage = async (
  pageId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<CustomPageProps>> => {
  const endpoint = `${getEndpoint().getCustomPageURL}/${pageId}`;
  const { data: response, status } = await apiHandler.get<{
    docs: ApiCustomPageProps;
  }>(endpoint, {
    signal,
  });

  return {
    status,
    doc: response?.docs ? transformCustomPage(response?.docs) : undefined,
  };
};

/**
 * Adds a new custom page.
 *
 * @returns {Promise<{ status: number; pageId?: string }>}
 * The status of the request and the ID of the newly created custom page.
 */
export const addCustomPage = async (): Promise<{
  status: number;
  pageId?: string;
}> => {
  const { addCustomPageURL: endpoint } = getEndpoint();
  const { data: response, status } = await apiHandler.post<{
    docs: { id: string };
  }>(endpoint);
  return { status, pageId: response?.docs?.id };
};

/**
 * Duplicates an existing custom page by its ID.
 *
 * @param {string} pageId - The ID of the custom page to duplicate.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const duplicateCustomPage = async (
  pageId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().duplicateCustomPageURL}/${pageId}`;
  const { status } = await apiHandler.post(endpoint);
  return { status };
};

/**
 * Updates an existing custom page by its ID.
 *
 * @param {string} id - The ID of the custom page to update.
 * @param {CustomPageDataProps} data - The data to update the custom page with.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const updateCustomPage = async (
  id: string,
  data: CustomPageDataProps
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().updateCustomPageURL}/${id}`;
  const { status } = await apiHandler.patch(endpoint, {
    title: data.title,
    slug: data.slug,
    isActive: data.isActive,
  });
  return { status };
};

/**
 * Deletes a custom page by its ID.
 *
 * @param {string} pageId - The ID of the custom page to delete.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const deleteCustomPage = async (
  pageId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().deleteCustomPageURL}/${pageId}`;
  const { status } = await apiHandler.delete(endpoint);
  return { status };
};

/**
 * Creates a custom page body with the provided data.
 *
 * @param {CustomPageBodyDataProps} bodyData - The data for creating the custom page body.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const createCustomPageBody = async (
  bodyData: CustomPageBodyDataProps
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().createCustomPageBodyURL}`;
  const body = bakeCustomPageBody({ id: 'draft', data: bodyData }).data;
  const { status } = await apiHandler.post(endpoint, body);
  return { status };
};

/**
 * Updates an existing custom page body by its ID.
 *
 * @param {string} bodyId - The ID of the custom page body to update.
 * @param {CustomPageBodyDataProps} bodyData - The data to update the custom page body with.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const updateCustomPageBody = async (
  bodyId: string,
  bodyData: CustomPageBodyDataProps
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().updateCustomPageBodyURL}/${bodyId}`;
  const body = bakeCustomPageBody({ id: bodyId, data: bodyData }).data;
  const { status } = await apiHandler.patch(endpoint, body);
  return { status };
};

/**
 * Duplicates an existing custom page body by its ID.
 *
 * @param {string} bodyId - The ID of the custom page body to duplicate.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const duplicateCustomPageBody = async (
  bodyId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().duplicateCustomPageBodyURL}/${bodyId}`;
  const { status } = await apiHandler.post(endpoint);
  return { status };
};

/**
 * Deletes a custom page body by its ID.
 *
 * @param {string} bodyId - The ID of the custom page body to delete.
 * @returns {Promise<{ status: number }>}
 * The status of the request.
 */
export const deleteCustomPageBody = async (
  bodyId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().deleteCustomPageBodyURL}/${bodyId}`;
  const { status } = await apiHandler.delete(endpoint);
  return { status };
};
