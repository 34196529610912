import cloneDeepWith from 'lodash/cloneDeepWith';

// Custom Types
import type { PatternDataType } from 'features/appBuilder/patterns/types/type';
import type { PatternType } from 'features/appBuilder/patterns/types/type';

export const patternTypes: PatternType[] = [
  'banner',
  'content',
  'footer',
  'form',
  'group',
  'header',
  'profile',
  'stable',
  'skinContent',
  'tagAndCategory',
  'search',
  'table',
];

export const patternTypesMap: {
  [key in PatternType]: string;
} = {
  banner: 'بنر',
  content: 'کانتنت',
  footer: 'فوتر',
  form: 'فرم',
  group: 'گروه',
  header: 'هدر',
  profile: 'پروفایل',
  stable: 'استیبل',
  search: 'جستجو',
  skinContent: 'تمپلیت کانتنت',
  tagAndCategory: 'کتگوری و برچسب',
  table: 'تیبل',
};

/**
 * Checks if the given pattern type is valid.
 * @param {string} type - The pattern type to check.
 * @returns {boolean} True if the pattern type is valid, false otherwise.
 */
export const isPatternTypeValid = (type: string): boolean =>
  patternTypes.includes(type as PatternType) ? true : false;

/**
 * Retrieves the title for the given pattern type.
 * @param {PatternType} type - The pattern type.
 * @returns {string} The title for the pattern type, or '-' if not found.
 */

export const getPatternTitle = (type: PatternType): string =>
  patternTypesMap[type] || '-';

/**
 * Bakes the pattern data by performing deep cloning and converting specific properties to their appropriate types.
 * @param {PatternDataType} data - The pattern data to bake.
 * @returns {PatternDataType} The baked pattern data.
 */
export const bakePattern = (data: PatternDataType): PatternDataType =>
  cloneDeepWith(data, (value, property) => {
    if (value === null || value === undefined) return null;

    const propertyName = property as unknown as string;

    // In Page Pattern image shape property name is 'width'. idk why, but with below conditional
    // We skip baking the width property on that pattern.
    const isPagePatternWidth =
      propertyName === 'width' &&
      ['full-width', 'medium', 'small'].includes(value);

    if (!isPagePatternWidth && shouldConvertToNumber(value, propertyName)) {
      if (
        ['maxLength', 'minLength'].includes(propertyName) &&
        Object.keys(value).length > 0
      )
        return value;
      if (
        propertyName === 'variant' &&
        ['outlined', 'contained', 'text'].includes(value)
      )
        return value;
      if (typeof value === 'string' && value.length > 0) return parseInt(value);
      else return null;
    }
  });

/**
 * Checks if a property in the pattern data needs to be converted to a number.
 *
 * @param {*} value - The value of the property.
 * @param {string} propName - The name of the property.
 * @returns {boolean} True if the property needs to be converted to a number, false otherwise.
 */

// This function check if a property in pattern data needs to convert to  number.
const shouldConvertToNumber = (value: any, propName: string): boolean =>
  [
    'sortNumber',
    'itemsCount',
    'minLength',
    'maxLength',
    'variant',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'size',
    'pageSize',
    'totalCards',
    'columnsCount',
    'width',
    'height',
    'gap',
  ].includes(propName) && typeof value !== 'number';
