// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';

// Custom Types
import type { ApiGetDocResponse } from 'core/types/api/hook/response';
import type {
  SheetDataProps,
  SheetListItemProps,
  SheetProps,
} from 'features/data/sheets/types';

/**
 * Adds a new sheet document.
 *
 * @param {SheetDataProps} sheet - The data of the sheet to be added.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const addSheet = async (
  sheet: SheetDataProps
): Promise<{
  status: number;
}> => {
  const { addSheet } = getEndpoint();
  const { status } = await apiHandler.post(addSheet, sheet);
  return { status };
};

/**
 * Retrieves a list of sheets.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [query] - Optional query parameters for filtering the sheets.
 * @returns {Promise<{ status: number, list: SheetListItemProps[] }>} A promise that resolves to an object containing the status and the list of sheets.
 */
export const getSheets = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<{
  status: number;
  list: SheetListItemProps[];
}> => {
  const { getSheets } = getEndpoint();
  const { status, data: response } = await apiHandler.get<{
    docs: SheetListItemProps[];
  }>(getUrlWithQueryString(getSheets, query), { signal });
  return { status, list: response?.docs || [] };
};

/**
 * Retrieves a specific sheet document by its ID.
 *
 * @param {string} sheetId - The ID of the sheet to retrieve.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocResponse<SheetProps>>} A promise that resolves to an object containing the status and the retrieved sheet document.
 */
export const getSheet = async (
  sheetId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<SheetProps>> => {
  const { getSheet } = getEndpoint();
  const endpoint = `${getSheet}/${sheetId}`;
  const { status, data: response } = await apiHandler.get<{ docs: SheetProps }>(
    endpoint,
    {
      signal,
    }
  );
  return { status, doc: response?.docs };
};

/**
 * Edits an existing sheet document by its ID.
 *
 * @param {string} sheetId - The ID of the sheet to edit.
 * @param {SheetDataProps} sheet - The updated data of the sheet.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const editSheet = async (
  sheetId: string,
  sheet: SheetDataProps
): Promise<{
  status: number;
}> => {
  const { editSheet } = getEndpoint();
  const endpoint = `${editSheet}/${sheetId}`;
  const { status } = await apiHandler.patch(endpoint, sheet);
  return { status };
};

/**
 * Deletes a sheet document by its ID.
 *
 * @param {string} sheetId - The ID of the sheet to delete.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const deleteSheet = async (
  sheetId: string
): Promise<{
  status: number;
}> => {
  const { deleteSheet } = getEndpoint();
  const { status } = await apiHandler.delete(`${deleteSheet}/${sheetId}`);
  return { status };
};
