import { type FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// Custom Hooks
import useMount from 'core/hooks/useMount';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Skeleton from 'core/components/base/feedback/Skeleton';

// Feature Components
import PatternActionBox from 'features/appBuilder/patterns/core/components/shared/ActionBox';
import PatternConfig from 'features/appBuilder/patterns/core/components/shared/Config';

// Feature Components
import HeaderPatternItems from 'features/appBuilder/patterns/features/header/components';
import FooterPatternItems from 'features/appBuilder/patterns/features/footer/components';
import ContentPatternItems from 'features/appBuilder/patterns/features/content/components';
import StablePatternItems from 'features/appBuilder/patterns/features/stable/components';
import ProfilePatternItems from 'features/appBuilder/patterns/features/profile/components';
import SliderPatternItems from 'features/appBuilder/patterns/features/slider/components';
import ContentDetailsPatternItems from 'features/appBuilder/patterns/features/contentDetails/components';
import TagAndCategoryPatternItems from 'features/appBuilder/patterns/features/tagAndCategory/components';
import SearchPatternItems from 'features/appBuilder/patterns/features/search/components';
import FormPatternItems from 'features/appBuilder/patterns/features/form/components';
import GroupPatternItem from 'features/appBuilder/patterns/features/group/components'; // Old Pattern
import EditTable from 'features/appBuilder/patterns/features/table/components/Edit'; // Old Pattern

// Core Utilities
import { useAppDispatch } from 'core/hooks/redux';

// Feature Utilities
import { usePatternDoc } from 'features/appBuilder/patterns/hooks';
import { updateTable } from 'features/appBuilder/patterns/features/table/store';
import { isPatternTypeValid } from 'features/appBuilder/patterns/core/utilities/helper/type';

// Custom Types
import type { PatternCommonProps } from 'features/appBuilder/patterns/core/types/item/common';
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';

export interface EditPatternProps {}

const EditPattern: FC<EditPatternProps> = () => {
  // States
  const [collapsed, setCollapsed] = useState<boolean>(false);

  // Hooks
  const { id, ...params } = useParams();
  const patternType = params?.patternType as PatternType;
  const isValidPattern = useMemo(
    () => (patternType && isPatternTypeValid(patternType) && id ? true : false),
    [id, patternType]
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formMethods = useForm<PatternCommonProps>();
  const patternMethods = usePatternDoc(patternType, {
    autoLoading: true,
    id,
    enabled: isValidPattern,
    onFetch: (pattern) => {
      formMethods.reset(pattern?.data);
      if (patternType === 'table') {
        dispatch(
          updateTable({
            id,
            data: {
              ...pattern?.data,
              initialized: true,
            },
          })
        );
      }
    },
  });

  useMount(() => {
    if (!isValidPattern) navigate('/pattern');
  });

  // Render
  return (
    <FormProvider {...formMethods}>
      <ColumnStack>
        <PatternActionBox editMode patternId={id} patternType={patternType} />
        {isValidPattern ? (
          <PatternConfig
            collapsed={collapsed}
            onCollapsedChange={setCollapsed}
            patternType={patternType}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ borderRadius: '0.5rem' }}
            height='4.5rem'
          />
        )}
        {patternType === 'header' && <HeaderPatternItems />}
        {patternType === 'footer' && <FooterPatternItems />}
        {patternType === 'content' && <ContentPatternItems />}
        {patternType === 'stable' && <StablePatternItems />}
        {patternType === 'profile' && <ProfilePatternItems />}
        {patternType === 'slider' && <SliderPatternItems />}
        {patternType === 'contentDetails' && <ContentDetailsPatternItems />}
        {patternType === 'tagAndCategory' && <TagAndCategoryPatternItems />}
        {patternType === 'search' && <SearchPatternItems />}
        {patternType === 'form' && <FormPatternItems />}
        {patternType === 'group' && <GroupPatternItem />}
        {patternType === 'table' && <EditTable />}
      </ColumnStack>
    </FormProvider>
  );
};

export default EditPattern;
