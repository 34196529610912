import * as React from 'react';
import { styled } from '@mui/material';

// Core Components
import Box from 'core/components/base/layout/Box';
import IconButton from 'core/components/base/inputs/IconButton';
import Paper from 'core/components/base/surfaces/Paper';

// Custom Common Commponents
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Icon Components
import InsertPhotoIcon from 'core/components/icons/InsertPhoto';
import CloseCircleIcon from 'core/components/icons/CloseCircle';

// Custom Utilities
import { prefixFileURL } from 'core/utilities/helper/link';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type {
  DeprecatedFileLocationType,
  FileProps,
  FileType,
} from 'features/file/files/types';

export interface PreviewProps {
  previewAlt?: string;
  previewSrc: string;
}

interface UploadProps extends ImageWrapperProps {
  previewProps?: PreviewProps;
  onUploadChange?: (files: FileProps[]) => void;
  onUploadRemove?: () => void;
  accept?: FileType[];
  location: DeprecatedFileLocationType;
}

interface ImageWrapperProps extends PaperProps {
  error?: boolean;
  disabled?: boolean;
}

const ImageWrapper = styled(Paper)<ImageWrapperProps>(({ theme, error }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  boxShadow: 'none',
  border: error ? `1px solid ${theme.palette.error.main}` : '',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '9rem',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  p: {
    color: theme.palette.text.disabled,
  },
}));

const Upload: React.FC<UploadProps> = React.forwardRef((props, ref) => {
  // Props
  const {
    error,
    disabled,
    previewProps,
    location,
    accept,
    onUploadChange,
    onUploadRemove,
    ...otherProps
  } = props;

  // Context
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    if (disabled) return;
    setSettings({
      open: true,
      accept,
      location,
      onInsertFile: (files) => onUploadChange && onUploadChange(files),
    });
  };

  // Render
  return (
    <ImageWrapper {...otherProps}>
      {previewProps && previewProps.previewSrc ? (
        <>
          <IconButton
            disabled={disabled}
            onClick={onUploadRemove}
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              zIndex: 10,
            }}
          >
            <CloseCircleIcon />
          </IconButton>
          <Box
            sx={{
              height: '100%',
              padding: '0.5rem',
              width: '100%',
              position: 'absolute',
            }}
          >
            <img
              alt=''
              src={prefixFileURL(previewProps.previewSrc)}
              style={{
                borderRadius: '4px',
                filter: disabled ? 'grayscale(50%) brightness(90%)' : 'none',
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
            />
          </Box>
        </>
      ) : (
        <Box
          onClick={handleOpenDialog}
          sx={{
            alignItems: 'inherit',
            cursor: disabled ? 'auto' : 'pointer',
            display: 'inherit',
            flexDirection: 'inherit',
            height: '100%',
            justifyContent: 'inherit',
            width: '100%',
          }}
        >
          <InsertPhotoIcon
            sx={{
              color: disabled ? 'text.disabled' : 'inherit',
              fontSize: '3rem',
            }}
          />
          <BodyOne>آپلود</BodyOne>
        </Box>
      )}
    </ImageWrapper>
  );
});

export default Upload;
