// Feature Types
import { PatternTabItemProps } from 'features/appBuilder/patterns/core/types/item/tab';
import { ContentPatternBodyProps } from 'features/appBuilder/patterns/features/content/types/body';

export const contentPatternTabDefaults: PatternTabItemProps = {
  isActive: true,
  refType: '',
  archiveLink: '',
  archiveTitle: '',
  padding: '0px',
  sourceId: '',
  sourceTitle: '',
  title: '',
};

export const contentPatternBodyDefaults: ContentPatternBodyProps = {
  isActive: true,
  tabs: [contentPatternTabDefaults],
};
