// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { bakeContentDetails } from 'features/content/contents/utilities/bake';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';

// Types
import type { DeprecatedContentProps } from 'features/content/contents/types/oldTypes';
import type { ContentDetailsDataProps } from 'features/content/contents/types/details';
import type { ContentListItemProps } from 'features/content/contents/types/list';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';

/**
 * Retrieves content data from the server.
 * @async
 * @param {Object} [configs] - Configuration object for the request.
 * @param {PageProps} [configs.page] - Optional page properties for pagination.
 * @returns {Promise<ApiGetDocsResponse<ContentListItemProps>>} A Promise that resolves with an object containing the HTTP status code, retrieved content data, and total document count (if successful).
 */

export const getContentsData = async (
  abortSignal: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<ContentListItemProps>> => {
  const { getContentsURL } = getEndpoint();

  const { data, status } = await apiHandler.get<{
    docs: { data: ContentListItemProps[]; paginate: ApiPaginationProps };
  }>(getUrlWithQueryString(getContentsURL, queries), {
    signal: abortSignal,
  });

  const page = data
    ? {
        current: data?.docs.paginate.page,
        size: data?.docs.paginate.limit,
        totalDocs: data?.docs.paginate.totalDocs,
      }
    : undefined;

  const list = data?.docs?.data || undefined;

  return { status, list, page };
};

/**
 * Deletes content with the specified IDs.
 *
 * @param {string[]} selection - An array of content IDs to delete.
 * @returns {Promise<{ status: number }>} The status of the delete request.
 */
export const deleteContent = async (
  selection: string[]
): Promise<{ status: number }> => {
  const { deleteContentURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.delete(endpoint, { ids: selection });
  return { status };
};

/**
 * Adds new content.
 *
 * @param {DeprecatedContentDataProps} data - The content data to add.
 * @returns {Promise<{ status: number }>} The status of the add request.
 */
export const addContent = async (
  data: ContentDetailsDataProps
): Promise<{ status: number }> => {
  const { addContentURL: endpoint } = getEndpoint();

  const dataToSave = {
    ...data,
    slug:
      data.slug !== ''
        ? data.slug
        : data.title
            .replaceAll(/[?$#!()@٬٫٪×،*+=:^&%--ـ]/g, '')
            .trim()
            .replaceAll(/ /g, '_'),
  };

  const body = bakeContentDetails(dataToSave);

  const { status } = await apiHandler.post(endpoint, body);
  return { status };
};

/**
 * Edits an existing content by its ID.
 *
 * @param {string} contentId - The ID of the content to edit.
 * @param {ContentDataProps} data - The updated content data.
 * @returns {Promise<{ status: number }>} The status of the edit request.
 */
export const editContent = async (
  contentId: string,
  data: ContentDetailsDataProps
): Promise<{ status: number }> => {
  const { editContentURL } = getEndpoint();
  const endpoint = `${editContentURL}/${contentId}`;

  const body = bakeContentDetails(data);

  const dataToSave = {
    ...body,
    slug:
      data.slug !== ''
        ? data.slug
        : data.title
            .replaceAll(/[?$#!()@٬٫٪×،*+=:^&%--ـ]/g, '')
            .trim()
            .replaceAll(/ /g, '_'),
  };

  const { status } = await apiHandler.patch(endpoint, dataToSave);
  return { status };
};
/**
 * Retrieves content data from the server.
 * @async
 * @param {string} contentId - The ID of the content to retrieve.
 * @returns {Promise<ApiGetDocResponse<DeprecatedContentProps>>} A Promise that resolves with an object containing the HTTP status code and the retrieved content data (if successful).
 */
export const getContentData = async (
  contentId: string,
  signal: AbortSignal
): Promise<ApiGetDocResponse<DeprecatedContentProps>> => {
  const { getContentURL } = getEndpoint();
  const endpoint = `${getContentURL}/${contentId}`;

  const { data, status } = await apiHandler.get<{
    docs: DeprecatedContentProps;
  }>(endpoint, {
    signal,
  });

  return { status, doc: data?.docs };
};
