import { useState, type FC, useEffect } from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Core Components
import Menu, { type MenuProps } from 'core/components/base/navigation/Menu';
import Select from 'core/components/base/inputs/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Button from 'core/components/base/inputs/Button';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { getSheets } from 'features/data/sheets/utilities/api';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';

// Custom Types
import type { SheetListItemProps } from 'features/data/sheets/types';
import type { TableCellRefProps } from 'features/appBuilder/patterns/features/table/types/cell';

export type CellRefOption = Pick<
  TableCellRefProps,
  'columnKey' | 'sheetId' | 'sheetTitle'
>;

export interface RefMenuProps extends MenuProps {
  selectedValue?: CellRefOption;
  onSubmitRef?: (opt: CellRefOption) => void;
  onMenuClose?: () => void;
}

const RefMenu: FC<RefMenuProps> = (props) => {
  // Props
  const {
    id = 'table-pattern-insert-ref-menu',
    MenuListProps,
    onSubmitRef,
    selectedValue,
    onMenuClose,
    ...otherProps
  } = props;

  // States
  const [sheetsData, setSheetsData] = useState<SheetListItemProps[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selected, setSelected] = useState<CellRefOption>({
    columnKey: '',
    sheetId: '',
    sheetTitle: '',
  });

  // Hooks
  const loading = useSelectLoading();
  const selection = useAppSelector((state) => state.table.data.selection);
  useEffect(() => {
    let active = true;
    if (active && props.open) {
      setSheets();
    }

    return () => {
      active = false;
    };
  }, [props.open]);

  // Utilities
  const setSheets = async () => {
    setAppLoading(true);
    const { list: sheets, status } = await getSheets();

    if ([201, 200].includes(status)) {
      if (selection.length > 0 && selection[0].type === 'ref') {
        const sheetId = selection[0].sheetId;
        setSelected({
          sheetId,
          columnKey: selection[0].columnKey,
          sheetTitle: selection[0].sheetTitle,
        });

        const defaultSelected = sheets.find((sheet) => sheet.id === sheetId);

        if (defaultSelected) {
          setHeaders(defaultSelected.data.headers);
        }
      }
      setSheetsData(sheets);
    } else {
      setAppAlert('خطا در دریافت لیست شیت‌ها');

      setSheetsData([]);
    }

    setAppLoading(false);
  };

  const handleSheetChange =
    (sheetId: string, sheetTitle: string, colNames: string[]) => () => {
      setHeaders(colNames);
      setSelected({ columnKey: '', sheetId, sheetTitle });
    };

  const handleHeaderChange = (key: string) => () => {
    setSelected((prev) => ({ ...prev, columnKey: key }));
  };

  const handleSubmit = () => {
    if (onSubmitRef) {
      onSubmitRef(selected);
      if (onMenuClose) onMenuClose();
    }
  };

  return (
    <Menu
      id={id}
      onClose={onMenuClose}
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          boxShadow: 3,
          padding: '1rem',
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'table-pattern-insert-ref-button',
        sx: { width: '12rem', p: 0 },
        ...MenuListProps,
      }}
      {...otherProps}
    >
      <ColumnStack>
        <ColumnStack spacing={1}>
          <InputLabel disabled={sheetsData.length === 0 || loading} required>
            انتخاب شیت
          </InputLabel>
          <Select
            disabled={sheetsData.length === 0 || loading}
            fullWidth
            sx={{ height: '3rem' }}
            value={selected.sheetId}
          >
            {sheetsData.map((sheetData) => (
              <MenuItem
                disabled={sheetsData.length === 0 || loading}
                onClick={handleSheetChange(
                  sheetData.id,
                  sheetData.data.title,
                  sheetData.data.headers
                )}
                key={sheetData.id}
                value={sheetData.id}
              >
                {sheetData.data.title}
              </MenuItem>
            ))}
          </Select>
        </ColumnStack>
        <ColumnStack spacing={1}>
          <InputLabel required disabled={!selected.sheetId || loading}>
            انتخاب سلول
          </InputLabel>
          <Select
            disabled={!selected.sheetId || loading}
            fullWidth
            sx={{ height: '3rem' }}
            value={selected.columnKey}
          >
            {headers.map((header, index) => (
              <MenuItem
                disabled={!selected.sheetId || loading}
                key={header + index}
                value={header}
                onClick={handleHeaderChange(header)}
              >
                {header}
              </MenuItem>
            ))}
          </Select>
        </ColumnStack>
        <Button
          onClick={handleSubmit}
          disabled={
            !selected.sheetId || !selected.sheetTitle || !selected.columnKey
          }
          variant='outlined'
        >
          اضافه کردن
        </Button>
      </ColumnStack>
    </Menu>
  );
};

export default RefMenu;
