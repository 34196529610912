// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { transformTemplateBodies } from 'features/appBuilder/templates/utilities/transformer';

// Custom Types
import type { TemplateProps } from 'features/appBuilder/templates/types';
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';
import type { TemplateListItemProps } from 'features/appBuilder/templates/types/listItem';
import type { ApiTemplateBodyProps } from 'features/appBuilder/templates/types/api';
import type { ApiGetDocResponse } from 'core/types/api/hook/response';

// !
export const getTemplates = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<{
  status: number;
  list: TemplateListItemProps[];
}> => {
  const endpoint = getEndpoint().getTemplatesURL;

  const { data: response, status } = await apiHandler.get<{
    docs: { [key in TemplateFeatureType]: string[] };
  }>(getUrlWithQueryString(endpoint, query), {
    signal,
  });

  const list: TemplateListItemProps[] = [];

  Object.entries(response?.docs || []).forEach(([f, skins]) => {
    const feature = f as TemplateFeatureType;

    list.push({
      id: `template-feature-${feature}`,
      data: {
        feature,
        skins,
      },
    });
  });

  return { status, list };
};

export const getTemplate = async (
  type: TemplateFeatureType,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<TemplateProps>> => {
  const endpoint = `${getEndpoint().getTemplateURL}/${type}`;

  const { status, data: response } = await apiHandler.get<{
    docs: { bodies: ApiTemplateBodyProps[] };
  }>(endpoint, {
    signal,
  });

  const template: TemplateProps = {
    id: `template-feature-${type}`,
    data: {
      feature: type,
      bodies: transformTemplateBodies(response?.docs?.bodies || []),
    },
  };

  return { status, doc: response?.docs ? template : undefined };
};
